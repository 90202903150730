import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import FooterBar from "../components/FooterBar";
import HomeSlider from "../components/HomeSlider";
import MenuHome from "../components/MenuHome";
import AuthService from "../services/auth.service";
import PendukungService from "../services/pendukung.service";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import Lottie from "react-lottie-player";
import lottieJson from "../lottie/19114-notification-bell.json";
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

export default function Home() {
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const [userReady, setUserReady] = useState(false);
  const [perjanjian, setPerjanjian] = useState([]);
  //   const [nik, setNik] = useState("");
  const [offlineStatus, setOfflineStatus] = useState(!navigator.onLine);
  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  function handleOfflineStatus() {
    setOfflineStatus(!offlineStatus);
  }

  const cekToken = () => {
    AuthService.checkToken().then((res) => {});
  };

  const cekPerjanjianAktif = () => {
    PendukungService.getPerjanjianAktif().then((res) => {
      let result = decryptData(token, res.data);
      for (let i = 0; i < result.length; i++) {
        result[i].id_perjanjian = encryptData(token, result[i].id_perjanjian);
      }
      setPerjanjian(result);
    });
  };

  const logout = () => {
    AuthService.logout();
    setCurrentUser(currentUser);
    toast.success("Anda berhasil keluar dari aplikasi.");
    navigate(0);
  };

  useEffect(() => {
    setCurrentUser(currentUser);
    if (!currentUser) {
    } else {
      setUserReady(true);
      cekPerjanjianAktif();
      cekToken();
    }
  }, []);

  return (
    <>
      <FooterBar />

      <div className="page-content">
        <div className="page-title page-title-small text-center">
          <img src="/png/Logo_mobi.png" width={250}></img>
        </div>
        <div
          className="card header-card shape-rounded"
          style={{ height: "280px" }}
        >
          <div className="card-overlay bg-highlight opacity-95"></div>
        </div>
        <HomeSlider />
        {perjanjian.length > 0 ? (
          <div
            className="ms-3 me-3 alert alert-small rounded-s shadow-xl bg-green-light"
            role="alert"
          >
            <span>
              <Lottie
                loop
                animationData={lottieJson}
                play
                style={{ width: 50 }}
              />
            </span>
            <strong>
              Anda memiliki perjanjian aktif. Lihat{" "}
              <Link to="/riwayat">disini</Link>
            </strong>
          </div>
        ) : (
          ""
        )}

        <MenuHome userReady={userReady} />

        <div className="card card-style" style={{ height: 260 }}>
          <div className="card-center ps-3 pe-3">
            <h4 className="color-white">Info Besuk</h4>
            <div className="content">
              <table
                className="table table-borderless text-center rounded-sm shadow-l"
                style={{ overflow: "hidden" }}
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      className="bg-dark-dark border-dark-dark color-white py-3 font-14"
                    >
                      PAGI
                    </th>
                    <th
                      scope="col"
                      className="bg-dark-dark border-dark-dark color-white py-3 font-14"
                    >
                      SORE
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr className="bg-dark-light">
                    <th scope="row">11.00 - 13.00 WIB</th>
                    <th scope="row">18.00 - 20.30 WIB</th>
                  </tr>
                  <tr className="bg-dark-light">
                    <th scope="row">
                      11.00 - 12.00 WIB <br />
                      (Ruang Khusus)
                    </th>
                    <th scope="row">
                      17.00 - 18.00 WIB <br />
                      (Ruang Khusus)
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="card-overlay bg-highlight opacity-90" />
        </div>

        <div className="card card-style mt-3 shadow-l" style={{ height: 220 }}>
          <div className="card-center ps-3 pe-3">
            <h4 className="color-white">Kontak Kami</h4>
            <div className="list-group list-custom-small">
              <a href="mailto:info@auliahospital.co.id">
                <i className="fa font-14 fa-envelope color-white" />
                <span className="color-white">info@auliahospital.co.id</span>
              </a>
              <a href="tel:07616700000">
                <i className="fa font-14 fa-phone color-white" />
                <span className="color-white">(+62 761) 67 00 000</span>
              </a>
              <a href="tel:08117560100">
                <i className="fa font-14 fa-mobile color-white" />
                <span className="color-white">0811 7560 100</span>
              </a>
            </div>
          </div>
          <div className="card-overlay bg-highlight opacity-90" />
        </div>

        {currentUser ? (
          <div className="card card-style mt-4 shadow-l" style={{ height: 50 }}>
            <div className="card-center ps-3 pe-3">
              <div className="list-group list-custom-small">
                <a href="#" onClick={logout}>
                  <i className="fa font-14 fa-sign-out color-white" />
                  <span className="color-white">Logout</span>
                </a>
              </div>
            </div>
            <div className="card-overlay bg-highlight opacity-90" />
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
