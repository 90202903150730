import toast from "react-hot-toast";
import React, { useState } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import FooterBar from "../components/FooterBar";

const ResetPassword = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [password, setPassword] = useState("");
  const [confirm_password, setRePassword] = useState("");
  const navigate = useNavigate();
  const lp = searchParams.get("c");

  const Auth = (e) => {
    e.preventDefault();

    if (password !== confirm_password) {
      toast.error("Password harus sama.");
      return false;
    }

    const toastId = toast.loading("Mohon tunggu...");

    AuthService.sendResetPassword({
      nik: lp,
      password: password,
      confirm_password: confirm_password,
    }).then(
      () => {
        toast.dismiss(toastId);
        toast.success("Password berhasil di ubah. Silahkan login kembali.");
        localStorage.removeItem("aulia_pwa");
        navigate("/login");
      },
      (error) => {
        toast.dismiss(toastId);
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        toast.error(resMessage);
      }
    );
  };

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div
          className="card bg-20"
          style={{ marginBottom: "-70px", height: "230px" }}
        >
          <div className="card-center text-center">
            <h1 className="color-white font-600 mb-n1 mt-n4 font-32 font-800">
              AULIA HOSPITAL
            </h1>
            <p className="color-white opacity-70">Your Healthcare Solution</p>
          </div>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="/assets/images/pictures/20s.jpg"
          />
        </div>

        <div className="card card-style">
          <div className="content mb-0">
            <div className="d-flex">
              <div className="me-auto">
                <h4 className="font-600">Ganti Password</h4>
                <p className="font-11 mt-n2 mb-3">
                  Silahkan isi password baru anda.
                </p>
              </div>
              <div className="ms-auto">
                <i
                  data-feather="user"
                  data-feather-line="1"
                  data-feather-size="40"
                  data-feather-color="blue-dark"
                  data-feather-bg="blue-fade-light"
                ></i>
              </div>
            </div>
            <div className="divider"></div>

            <form onSubmit={Auth}>
              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="password"
                  className="form-control validate-name"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  Password Baru
                </label>
                <i className="fa fa-times disabled invalid color-red-dark" />
                <i className="fa fa-check disabled valid color-green-dark" />
                <em>(required)</em>
              </div>
              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="password"
                  className="form-control validate-name"
                  value={confirm_password}
                  onChange={(e) => setRePassword(e.target.value)}
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  Konfirmasi Password Baru
                </label>
                <i className="fa fa-times disabled invalid color-red-dark" />
                <i className="fa fa-check disabled valid color-green-dark" />
                <em>(required)</em>
              </div>
              <button
                type="submit"
                className="btn form-control btn-block btn-m mt-4 mb-4 btn-full bg-highlight rounded-sm text-uppercase font-900"
              >
                Ubah Password
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
