import React, { useEffect } from "react";
import SignInForm from "./pages/SignInForm";
import initTemplate from "./custom.js";
import Home from "./pages/Home";
import RegisterForm from "./pages/RegisterForm";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Poliklinik from "./pages/Poliklinik";
import DokterList from "./pages/DokterList";
import ContactUs from "./pages/ContactUs";
import JadwalDokter from "./pages/JadwalDokter";
import Riwayat from "./pages/Riwayat";
import Profil from "./pages/Profil";
import Pasiens from "./pages/Pasiens";
import AddPasien from "./pages/AddPasien";
import PendaftaranRawatJalan from "./pages/PendaftaranRawatJalan";
import { Toaster } from "react-hot-toast";
import TautkanPasien from "./pages/TautkanPasien";
import BlockedPage from "./components/BlockedPage";
import Perjanjian from "./pages/Perjanjian";
import Akun from "./pages/Akun";
import SurveyAdmission from "./pages/SurveyAdmission";
import EmailVerif from "./pages/EmailVerif";
import Bmi from "./pages/Bmi";
import PaketMcu from "./pages/PaketMcu";
import ForgotPassword from "./pages/ForgotPassword";
import ResetPassword from "./pages/ResetPassword";

function App() {
  useEffect(() => {
    initTemplate();
  }, []);

  return (
    <div id="page">
      <div>
        {/* <Toaster /> */}
        <Toaster position="top-center" reverseOrder={false} containerStyle={{ top: 165 }}/>
      </div>
      <Router>
        <Routes>
          <Route path="/login" element={<SignInForm />} />
          <Route path="/" element={<Home />} />
          <Route path="/profil" element={<Profil />} />
          <Route path="/registrasi" element={<RegisterForm />} />
          <Route path="/rawat-jalan" element={<PendaftaranRawatJalan />} />
          <Route path="/dokter" element={<DokterList />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/jadwal-dokter" element={<JadwalDokter />} />
          <Route path="/riwayat" element={<Riwayat />} />
          <Route path="/pasiens" element={<Pasiens />} />
          <Route path="/add-pasien" element={<AddPasien />} />
          <Route path="/tautkan-pasien" element={<TautkanPasien />} />
          <Route path="/block" element={<BlockedPage />} />
          {/* <Route path='/perjanjian' element={<Perjanjian />} /> */}
          <Route path="/akun" element={<Akun />} />
          <Route path="/layanan" element={<Poliklinik />} />
          <Route path="/survey" element={<SurveyAdmission />} />
          <Route path="/vrf" element={<EmailVerif />} />
          <Route path="/bmi" element={<Bmi />} />
          <Route path="/paket" element={<PaketMcu />} />
          <Route path="/forgot" element={<ForgotPassword />} />
          <Route path="/lp" element={<ResetPassword />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
