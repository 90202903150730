import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import FooterBar from '../components/FooterBar';
import ShapeRound from '../components/ShapeRound';
import OtherService from '../services/other.service';
import toast from 'react-hot-toast';
import PendukungService from "../services/pendukung.service";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const PaketMcu = () => {
    const [paket, setPaket] = useState([]);
    const [showPaket, setShowDetail] = useState(false);
    const handleClosePaket = () => setShowDetail(false);
    const [infoPaket, setInfoPaket] = useState([]);
  
    const user = JSON.parse(localStorage.getItem('aulia_pwa'));
    const token = user ? user.token : bearier_token;

    const detailPaket = (idpaket) => {
        setShowDetail(true);
        const toastz = toast.loading('Mohon tunggu...');
        PendukungService.getDetailPaketMcu(idpaket).then((res) => {
            const result = decryptData(token, res.data);
            setInfoPaket(result);
            toast.dismiss(toastz)
        }, error => {
            toast.dismiss(toastz)
            console.log(error);
        })
    }

    const getPaket = () => {
        const toa = toast.loading("Mohon tunggu...")
        OtherService.paketMcu().then((res) => {
            let result = decryptData(token, res.data);
            for (let i = 0; i < result.length; i++) {
                result[i].id_paket = encryptData(token, result[i].id_paket);
            }
            setPaket(result);
            toast.dismiss(toa)
        }, error => {

        })
    }

    useEffect(() => {
        getPaket();
    }, [])

    return (
        <>
            <FooterBar />
            <div className="page-content">
                <div className="page-title page-title-small">
                    <h2>Paket Medical Check Up</h2>
                </div>
                <ShapeRound />
                {paket.map((p, i) => (
                    <div key={i}
                        className="card card-style rounded-m shadow-xl bg-18"
                        style={{ height: 140 }}
                    >
                        <div className="card-top mt-4 ms-3">
                            <h2 className="color-white">{p.nama_paket}</h2>
                            <p className="color-white font-10 opacity-70 mt-2 mb-1">
                                PAKET {p.poli}
                            </p>
                            {/* <button onClick={() => detailPaket(p.id_paket)} className="btn btn-full btn-xxs rounded-s text-uppercase font-900 shadow-xl border-highlight color-highlight">Lihat Detail Paket</button> */}
                        </div>
                        <div className="card-center me-3">
                            <a
                                href="#"
                                className="float-end bg-highlight btn btn-xs text-uppercase font-900 rounded-xl font-11"
                            >
                                IDR {p.harga_jual}
                            </a>
                        </div>
                        <div className="card-overlay bg-black opacity-80" />
                    </div>

                ))
                }

                <Modal
                    show={showPaket}
                    onHide={handleClosePaket}
                    backdrop="static"
                    keyboard={false}
                >
                    <Modal.Header closeButton>
                        <Modal.Title className="font-16 font-600">List Detail Paket</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table
                            className="table table-borderless text-center rounded-sm shadow-l"
                            style={{ overflow: "hidden" }}
                        >
                            <thead>
                                <tr className="bg-gray-light">
                                    <th scope="col" className="color-theme py-3 font-14">
                                        Jenis Komponen
                                    </th>
                                    <th scope="col" className="color-theme py-3 font-14">
                                        Detail Paket
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoPaket.map((j) => (
                                    <tr>
                                        <th scope="row">{j.jenis_komponen}</th>
                                        <td>{j.nama_detail}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-full btn-border btn-sm rounded-s text-uppercase font-900 shadow-l" onClick={handleClosePaket} variant="secondary">
                            Tutup
                        </Button>

                    </Modal.Footer>
                </Modal>
            </div>
        </>
    )
}

export default PaketMcu