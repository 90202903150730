import React from 'react'

const Error = () => {
  return (
    <div className="card card-style">
      <div className="content text-center">
        <h2>Error!</h2>
        
      </div>
    </div>

  )
}

export default Error