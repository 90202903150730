import axios from "axios";
import authHeader from './auth-header';
import { API_URL } from "./auth.service";

class PerjanjianService {

  buatPerjanjian(data) {
    return axios.post(API_URL + "pwa-perjanjian/tambah-perjanjian", data, { headers: authHeader() });
  }

  batalPerjanjian(data) {
    return axios.post(API_URL + "pwa-perjanjian/hapus-perjanjian", data, { headers: authHeader() });
  }
}

export default new PerjanjianService();