import { Link, useNavigate } from "react-router-dom";

export default function FooterBar() {
  const navigate = useNavigate();
  const goHome = () => {
    navigate("/");
    // window.location.reload();
  };
  return (
    <div id="footer-bar" className="footer-bar-5">
      <a onClick={goHome} style={{ cursor: "pointer" }}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-home"
          data-feather-line={1}
          data-feather-size={21}
          data-feather-color="blue-dark"
          data-feather-bg="blue-fade-light"
          style={{ strokeWidth: 1, width: 21, height: 21 }}
        >
          <path d="M3 9l9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
          <polyline points="9 22 9 12 15 12 15 22" />
        </svg>
        <span>Home</span>
      </a>

      <Link to="/riwayat">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-book-open"
          data-feather-line={1}
          data-feather-size={21}
          data-feather-color="green-dark"
          data-feather-bg="green-fade-light"
          style={{ strokeWidth: 1, width: 21, height: 21 }}
        >
          <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
          <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
        </svg>

        <span>Riwayat</span>
      </Link>
      <Link to="/profil">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width={24}
          height={24}
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth={2}
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-users"
          data-feather-line={1}
          data-feather-size={21}
          data-feather-color="red-dark"
          data-feather-bg="red-fade-light"
          style={{ strokeWidth: 1, width: 21, height: 21 }}
        >
          <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2" />
          <circle cx={9} cy={7} r={4} />
          <path d="M23 21v-2a4 4 0 0 0-3-3.87" />
          <path d="M16 3.13a4 4 0 0 1 0 7.75" />
        </svg>

        <span>Profil</span>
      </Link>
    </div>
  );
}
