import { useState } from "react"
import { Form, Field } from 'react-final-form'
import FooterBar from "../components/FooterBar"
import ShapeRound from "../components/ShapeRound"
import OtherService from "../services/other.service"
import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from "react-router-dom"

const SurveyAdmission = () => {
    const [namaPasien, setNamaPasien] = useState("");
    const [puas, setPuas] = useState("");
    const [alasan, setAlasan] = useState("");
    const kepuasan = ["Ya", "Tidak"]
    const [searchParams, setSearchParams] = useSearchParams();
    const c = searchParams.get("y");

    const navigate = useNavigate();
    const onSubmit = (values) => {
        // nama_pasien,salam, kepuasan, alasan, id - params
        // survey-kepuasan - link
        Object.assign(values, { y: c })
        console.log(values);
        const toastId = toast.loading('Mohon tunggu...');
        OtherService.saveSurveyKepuasan(values).then((res) => {
            toast.success("Terima kasih atas waktunya.");
            toast.dismiss(toastId);
            navigate("/")
        }, error => {
            toast.dismiss(toastId);
        })
    }

    const Error = ({ name }) => (
        <Field
            name={name}
            subscribe={{ touched: true, error: true }}
            render={({ meta: { touched, error } }) =>
                touched && error ? <span className="text-danger">{error}</span> : null
            }
        />
    )
    const required = value => (value ? undefined : 'Wajib diisi');

    return (
        <>
            <FooterBar />
            <div className="page-content">
                <div className="page-title page-title-small text-center">
                    <h2>AULIA HOSPITAL</h2>
                    <span className="color-white">Youre Healthcare Solution</span>
                </div>
                <ShapeRound />

                <div className="card card-style">
                    <div className="content">

                        <div className="mb-4">
                            <h3>Survey Pelayanan</h3>
                            <p>
                                Bantu kami menjadi lebih baik lagi dengan mengisi form survey dibawah ini:
                            </p>
                        </div>

                        <Form
                            onSubmit={onSubmit}
                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>

                                    <div className="mt-3 input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                        <label className="font-400 font-13">
                                            Nama Anda:
                                        </label>
                                        <Field
                                            name="nama_pasien"
                                            component="input"
                                            type="text"
                                            validate={required}
                                        />
                                        <Error name="nama_pasien" />
                                    </div>

                                    <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                        <label className="font-400 font-13">
                                            Apakah petugas kami memberikan salam?
                                        </label>
                                        <Field name="salam" component="select" validate={required}>
                                            <option>Pilih</option>
                                            <option value="Ya">Ya</option>
                                            <option value="Tidak">Tidak</option>
                                        </Field>

                                        <Error name="salam" />
                                    </div>

                                    <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                        <label className="font-400 font-13">
                                            Apakah anda puas dengan pelayanan kami?
                                        </label>
                                        <Field name="kepuasan">
                                            {({ select, meta }) => (
                                                <div>
                                                    <select {...select} onChange={(e) => setPuas(e.target.value)}>
                                                        <option>Pilih</option>
                                                        <option value="Ya">Ya</option>
                                                        <option value="Tidak">Tidak</option>
                                                    </select>
                                                    {meta.error && meta.touched && <span>{meta.error}</span>}
                                                </div>
                                            )}
                                        </Field>

                                        <Error name="kepuasan" />
                                    </div>
                                    {puas === 'Tidak' &&
                                        <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                            <Field
                                                name="catatan"
                                                component="textarea"
                                                type="textarea"
                                                validate={required}
                                            />
                                            <label className="font-400 font-13">
                                                Ceritakan Pengalaman/ Kritik/ Saran Anda:
                                            </label>
                                            <Error name="catatan" />
                                        </div>
                                    }
                                    <button type="submit" className="btn form-control btn-full btn-m rounded-s text-uppercase font-900 shadow-xl bg-highlight">Kirim</button>

                                </form>
                            )}
                        />

                    </div>
                </div>

            </div>
        </>
    )
}

export default SurveyAdmission