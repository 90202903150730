import { useEffect, useState } from "react";
import PasienService from "../services/pasien.service";
import AuthService from "../services/auth.service";
import FooterBar from "../components/FooterBar";
import { useNavigate, createSearchParams, Link } from "react-router-dom";
import toast from 'react-hot-toast';
import CardTitle from "../components/CardTitle";
import Lottie from 'react-lottie-player';
import lottieJson from '../lottie/73061-search-not-found.json';
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const Pasiens = () => {

  const [pasien, setPasien] = useState([]);
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const getPasiens = () => {
    const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
    PasienService.getPasien().then(
      (res) => {
        const result = decryptData(token, res.data);
        setPasien(result);
        toast.dismiss(toastId);
      },
      error => {
        toast.dismiss(toastId);
        if (error.response.status === 401 || error.response.status === 402) {
          localStorage.removeItem("aulia_pwa");
          navigate("/login");
        }
      }
    );
  }

  const lanjut = (idpasien) => {
    console.log(idpasien);
    navigate({
      pathname: '/rawat-jalan',
      search: createSearchParams({ r: idpasien }).toString(),
    })
  }

  useEffect(() => {
    setCurrentUser(currentUser);
    if (!currentUser) {
      localStorage.removeItem("aulia_pwa");
      navigate("/login");
      // window.location.reload();
    }

    getPasiens();

  }, []);

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div className="page-title page-title-small">
          <h2>
            <a onClick={() => navigate("/")}>
              <i className="fa fa-arrow-left" />
            </a>

            Buat Perjanjian

          </h2>
        </div>
        <div className="card header-card shape-rounded" style={{ height: "150px" }}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
        </div>

        {pasien.length === 0 ?

          <CardTitle>
            <div>
              <h2 className="mb-0 pt-1">Oops!</h2>
              <Lottie
                loop
                animationData={lottieJson}
                play
              />
              <p className="text-center">
                Kami tidak menemukan profil siapapun disini.<br/>
                Silahkan tambahkan profil pasien di menu <Link to="/profil">Profil</Link>.
              </p>
            </div>
          </CardTitle>

          :

          <div>
            <CardTitle>
              <div>
                <h2 className="mb-0 pt-1">Profil Pasien</h2>
                <p>
                  Silahkan pilih profil pasien yang akan membuat perjanjian ke poliklinik.
                </p>
              </div>
            </CardTitle>
            {pasien.map((p) => (

              <div className="btn card card-style" key={p.nik} onClick={() => lanjut(p.id_pasien)}>
                <div className="content">
                  <div className="d-flex mb-0">
                    <div>
                      <img
                        src="/assets/images/avatars/2s.png"
                        width={50}
                        className="me-3 bg-highlight rounded-xl"
                      />
                    </div>
                    <div>
                      <h4 className="mb-0 pt-1">{p.nama_lengkap}</h4>
                      <p>{p.nik}</p>

                    </div>
                  </div>

                </div>
              </div>
            ))}
          </div>
        }


      </div>
    </>
  )
}

export default Pasiens