import { useNavigate } from "react-router-dom";
import AuthService from "../services/auth.service";
import toast from 'react-hot-toast';
import { useEffect, useState } from "react";
import FooterBar from "../components/FooterBar";
import ShapeRound from "../components/ShapeRound";

import Lottie from 'react-lottie-player';
import lottieJson from '../lottie/82974-add-user.json';

import { bearier_token } from "../services/auth.service";
import { decryptData } from "../helpers/Secure";


const Akun = () => {
  const navigate = useNavigate();
  const [usr, setUst] = useState({});
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const logout = () => {
    AuthService.logout();
    toast.success("Anda berhasil keluar dari aplikasi.")
    navigate("/");
  }

  const cekToken = () => {
    AuthService.checkToken().then((res) => {
      const result = decryptData(token, res.data);
      setUst(result);
    }, error => {
      toast.error("Anda harus login terlebih dahulu")
      localStorage.removeItem("aulia_pwa");
      navigate("/login");
    });
  }

  useEffect(() => {
    setCurrentUser(currentUser);

    if (!currentUser) {
      toast.error("Untuk mengakses halaman ini, anda harus login terlebih dahulu.")
      localStorage.removeItem("aulia_pwa");
      navigate("/login")
    } else {
      cekToken();
    }

  }, []);

  return (
    <>
      <FooterBar />
      <div className="page-content">

        <div className="page-title page-title-small">
          <h2>Akun</h2>
        </div>

        <ShapeRound />

        <div className="card card-style">
          <div className="d-flex content mb-1">
            {/* left side of profile */}
            <div className="flex-grow-1">
              <h3 className="font-700">
                {usr.email}
               
              </h3>
              <p className="mb-2">
               
              </p>
              <p className="font-13">
                <strong className="color-theme pe-1">NIK: </strong>{usr.nik}
              </p>
            </div>
            {/* right side of profile. increase image width to increase column size*/}
            {/* <img
              src="/assets/images/avatars/4s.png"
              width={115}
              className="bg-highlight rounded-circle mt-3 shadow-xl preload-img entered loaded"
            /> */}
            <Lottie
              loop
              animationData={lottieJson}
              play
              style={{ width:100 }}
            />
          </div>
          {/* follow buttons*/}
          <div className="content">
            <div className="row mb-0">
              <div className="col-6">
                {/* <a
                  href="#"
                  className="btn btn-full btn-sm rounded-s text-uppercase font-900 bg-blue-dark"
                >
                  Ubah
                </a> */}
              </div>
              <div className="col-6">
                <button
                  onClick={logout}
                  className="btn form-control btn-full btn-sm btn-border rounded-s text-uppercase font-900 color-highlight border-blue-dark"
                >
                  Logout
                </button>
              </div>
            </div>
          </div>

        </div>


      </div>
    </>
  )
}

export default Akun