import * as bootstrap from 'bootstrap';
import * as feather from 'feather-icons';
import { Splide } from '@splidejs/splide';

const initTemplate = () => {

    const pwaName = "a";
    
    //Attaching Menu Hider
    var menuHider = document.getElementsByClassName('menu-hider');
    if (!menuHider.length) { var hider = document.createElement('div'); hider.setAttribute("class", "menu-hider"); document.body.insertAdjacentElement('beforebegin', hider); }
    if (menuHider[0].classList.contains('menu-active')) { menuHider[0].classList.remove('menu-active'); }

    //Activating Menus
    document.querySelectorAll('.menu').forEach(el => { el.style.display = 'block' })

    //Background Gradient Color
    var gradientData = document.querySelectorAll('[data-change-background]');
    gradientData.forEach(el => el.addEventListener('click', e => {
        var gradient = el.getAttribute('data-change-background');
        document.body.setAttribute('data-gradient', 'body-' + gradient + '');
        localStorage.setItem(pwaName + '-Gradient', gradient)
    }));

    //Set Background and Highlight
    var pageBackground = localStorage.getItem(pwaName + '-Gradient');
    if (pageBackground) { document.body.setAttribute('data-gradient', 'body-' + pageBackground + ''); }

    //Page Highlights
    function highlightColors() {
        var highlightData = document.querySelectorAll('[data-change-highlight]');
        highlightData.forEach(el => el.addEventListener('click', e => {
            const activeHighlight = document.querySelectorAll('.highlight-active');
            for (let i = 0; i < activeHighlight.length; i++) { activeHighlight[i].classList.remove('highlight-active'); }
            el.classList.add('highlight-active');
            var highlight = el.getAttribute('data-change-highlight');
            var pageHighlight = document.querySelectorAll('.page-highlight');
            if (pageHighlight.length) { pageHighlight.forEach(function (e) { e.remove(); }); }
            var loadHighlight = document.createElement("link");
            loadHighlight.rel = "stylesheet";
            loadHighlight.className = "page-highlight";
            loadHighlight.type = "text/css";
            loadHighlight.href = 'styles/highlights/highlight_' + highlight + '.css';
            document.getElementsByTagName("head")[0].appendChild(loadHighlight);
            document.body.setAttribute('data-highlight', 'highlight-' + highlight)
            localStorage.setItem(pwaName + '-Highlight', highlight)
        }))
        var rememberHighlight = localStorage.getItem(pwaName + '-Highlight');
        if (rememberHighlight) {
            var loadHighlight = document.createElement("link");
            loadHighlight.rel = "stylesheet";
            loadHighlight.className = "page-highlight";
            loadHighlight.type = "text/css";
            loadHighlight.href = 'styles/highlights/highlight_' + rememberHighlight + '.css';
            if (!document.querySelectorAll('.page-highlight').length) {
                document.getElementsByTagName("head")[0].appendChild(loadHighlight);
                document.body.setAttribute('data-highlight', 'highlight-' + rememberHighlight)
            }
        }
    }
    highlightColors();

    //Dropdown
    var dropdownElementList = [].slice.call(document.querySelectorAll('[data-bs-toggle="dropdown"]'))
    if (dropdownElementList.length) {
        var dropdownList = dropdownElementList.map(function (dropdownToggleEl) {
            return new bootstrap.Dropdown(dropdownToggleEl);
        })
    }

    feather.replace();
    function featherIcons() {
        var featherIcon = document.querySelectorAll('.feather');
        featherIcon.forEach(el => {
            var strokeWidth = el.getAttribute('data-feather-line');
            var featherSize = el.getAttribute('data-feather-size');

            el.style.strokeWidth = strokeWidth;
            el.style.width = featherSize;
            el.style.height = featherSize;
        });
    }
    featherIcons();

    function shapeChanger() {
        var shapeCutLeft = document.getElementById('shape-cut-left');
        var shapeCutRight = document.querySelectorAll('#shape-cut-right')[0];
        var shapeCutRound = document.querySelectorAll('#shape-rounded')[0];
        var headerCard = document.querySelectorAll('.header-card')[0];
        var colorCard = document.querySelectorAll('.color-card')[0];
        var footerCard = document.querySelectorAll('.footer-card')[0];

        if (shapeCutLeft) {
            shapeCutLeft.addEventListener('click', function (e) {
                headerCard.classList.remove('shape-rounded', 'shape-cut-right')
                headerCard.classList.add('shape-cut-left')
                footerCard.classList.remove('shape-rounded', 'shape-cut-right')
                footerCard.classList.add('shape-cut-left')
                colorCard.classList.remove('shape-rounded', 'shape-cut-right')
                colorCard.classList.add('shape-cut-left')
            })

            shapeCutRight.addEventListener('click', function () {
                headerCard.classList.remove('shape-rounded', 'shape-cut-left')
                headerCard.classList.add('shape-cut-right')
                footerCard.classList.remove('shape-rounded', 'shape-cut-left')
                footerCard.classList.add('shape-cut-right')
                colorCard.classList.remove('shape-rounded', 'shape-cut-left')
                colorCard.classList.add('shape-cut-right')
            })

            shapeCutRound.addEventListener('click', function () {
                headerCard.classList.remove('shape-cut-left', 'shape-cut-right')
                headerCard.classList.add('shape-rounded')
                footerCard.classList.remove('shape-cut-left', 'shape-cut-right')
                footerCard.classList.add('shape-rounded')
                colorCard.classList.remove('shape-cut-left', 'shape-cut-right')
                colorCard.classList.add('shape-rounded')
            })
        }
    }

    //Creating Offline Alert Messages
    var addOfflineClasses = document.querySelectorAll('.offline-message');
    if (!addOfflineClasses.length) {
        const offlineAlert = document.createElement('p');
        const onlineAlert = document.createElement('p');
        offlineAlert.className = 'offline-message bg-red-dark color-white';
        offlineAlert.textContent = 'No internet connection detected';
        onlineAlert.className = 'online-message bg-green-dark color-white';
        onlineAlert.textContent = 'You are back online';
        document.getElementsByTagName('body')[0].appendChild(offlineAlert);
        document.getElementsByTagName('body')[0].appendChild(onlineAlert);
    }

    //Don't jump on Empty Links
    const emptyHref = document.querySelectorAll('a[href="#"]')
    emptyHref.forEach(el => el.addEventListener('click', e => {
        e.preventDefault();
        return false;
    }));

    var checkedCard = document.querySelectorAll('.check-card');
    checkedCard.forEach(el => el.addEventListener('click', e => {
        if (el.querySelector('input').getAttribute('checked') == "checked") {
            el.querySelector('input').removeAttribute('checked');
        } else {
            el.querySelector('input').setAttribute('checked', 'checked');
        }
    }));

    //Card Effects
    const cardScale = document.querySelectorAll('.card-scale');
    if (cardScale.length) {
        cardScale.forEach(el => el.addEventListener('mouseenter', event => { el.querySelectorAll('img')[0].classList.add('card-scale-image'); }));
        cardScale.forEach(el => el.addEventListener('mouseleave', event => { el.querySelectorAll('img')[0].classList.remove('card-scale-image'); }));
    }

    const cardHide = document.querySelectorAll('.card-hide');
    if (cardHide.length) {
        cardHide.forEach(el => el.addEventListener('mouseenter', event => { el.querySelectorAll('.card-center, .card-bottom, .card-top, .card-overlay')[0].classList.add('card-hide-image'); }));
        cardHide.forEach(el => el.addEventListener('mouseleave', event => { el.querySelectorAll('.card-center, .card-bottom, .card-top, .card-overlay')[0].classList.remove('card-hide-image'); }));
    }

    const cardRotate = document.querySelectorAll('.card-rotate');
    if (cardRotate.length) {
        cardRotate.forEach(el => el.addEventListener('mouseenter', event => { el.querySelectorAll('img')[0].classList.add('card-rotate-image'); }));
        cardRotate.forEach(el => el.addEventListener('mouseleave', event => { el.querySelectorAll('img')[0].classList.remove('card-rotate-image'); }));
    }

    const cardGray = document.querySelectorAll('.card-grayscale');
    if (cardGray.length) {
        cardGray.forEach(el => el.addEventListener('mouseenter', event => { el.querySelectorAll('img')[0].classList.add('card-grayscale-image'); }));
        cardGray.forEach(el => el.addEventListener('mouseleave', event => { el.querySelectorAll('img')[0].classList.remove('card-grayscale-image'); }));
    }

    const cardBlur = document.querySelectorAll('.card-blur');
    if (cardBlur.length) {
        cardBlur.forEach(el => el.addEventListener('mouseenter', event => { el.querySelectorAll('img')[0].classList.add('card-blur-image'); }));
        cardBlur.forEach(el => el.addEventListener('mouseleave', event => { el.querySelectorAll('img')[0].classList.remove('card-blur-image'); }));
    }


    //Card Extender
    const cards = document.getElementsByClassName('card');
    function card_extender() {
        var headerHeight, footerHeight, headerOnPage;
        var headerOnPage = document.querySelectorAll('.header:not(.header-transparent)')[0];
        var footerOnPage = document.querySelectorAll('#footer-bar')[0];

        headerOnPage ? headerHeight = document.querySelectorAll('.header')[0].offsetHeight : headerHeight = 0
        footerOnPage ? footerHeight = document.querySelectorAll('#footer-bar')[0].offsetHeight : footerHeight = 0

        for (let i = 0; i < cards.length; i++) {
            if (cards[i].getAttribute('data-card-height') === "cover") {
                if (window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.outerHeight; }
                if (!window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.innerHeight; }
                //Fix for iOS 15 pages with data-height="cover"
                var coverHeight = windowHeight + 'px';
                // - Remove this for iOS 14 issues - var coverHeight = windowHeight - headerHeight - footerHeight + 'px';
            }
            if (cards[i].getAttribute('data-card-height') === "cover-card") {
                var windowHeight = window.innerHeight;
                var coverHeight = windowHeight - 173 + 'px';
                cards[i].style.height = coverHeight
            }
            if (cards[i].getAttribute('data-card-height') === "cover-full") {
                if (window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.outerHeight; }
                if (!window.matchMedia('(display-mode: fullscreen)').matches) { var windowHeight = window.innerHeight; }
                var coverHeight = windowHeight + 'px';
                cards[i].style.height = coverHeight
            }
            if (cards[i].hasAttribute('data-card-height')) {
                var getHeight = cards[i].getAttribute('data-card-height');
                cards[i].style.height = getHeight + 'px';
                if (getHeight === "cover") {
                    var totalHeight = getHeight
                    cards[i].style.height = coverHeight
                }
            }
        }
    }

    if (cards.length) {
        card_extender();
        window.addEventListener("resize", card_extender);
    }

    //Setting Sidebar Widths
    var menus = document.querySelectorAll('.menu');
    function menuFunction() {
        if (menus.length) {
            var menuSidebar = document.querySelectorAll('.menu-box-left, .menu-box-right');
            menuSidebar.forEach(function (e) {
                if (e.getAttribute('data-menu-width') === "cover") {
                    e.style.width = '100%'
                } else {
                    e.style.width = (e.getAttribute('data-menu-width')) + 'px'
                }
            })
            var menuSheets = document.querySelectorAll('.menu-box-bottom, .menu-box-top, .menu-box-modal');
            menuSheets.forEach(function (e) {
                if (e.getAttribute('data-menu-width') === "cover") {
                    e.style.width = '100%'
                    e.style.height = '100%'
                } else {
                    e.style.width = (e.getAttribute('data-menu-width')) + 'px'
                    e.style.height = (e.getAttribute('data-menu-height')) + 'px'
                }
            })

            //Opening Menus
            var menuOpen = document.querySelectorAll('[data-menu]');
            var wrappers = document.querySelectorAll('.header, #footer-bar, .page-content');

            menuOpen.forEach(el => el.addEventListener('click', e => {
                //Close Existing Opened Menus
                const activeMenu = document.querySelectorAll('.menu-active');
                for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
                //Open Clicked Menu
                var menuData = el.getAttribute('data-menu');
                document.getElementById(menuData).classList.add('menu-active');
                document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
                //Check and Apply Effects
                var menu = document.getElementById(menuData);
                var menuEffect = menu.getAttribute('data-menu-effect');
                var menuLeft = menu.classList.contains('menu-box-left');
                var menuRight = menu.classList.contains('menu-box-right');
                var menuTop = menu.classList.contains('menu-box-top');
                var menuBottom = menu.classList.contains('menu-box-bottom');
                var menuWidth = menu.offsetWidth;
                var menuHeight = menu.offsetHeight;
                var menuTimeout = menu.getAttribute('data-menu-hide');

                if (menuTimeout) {
                    setTimeout(function () {
                        document.getElementById(menuData).classList.remove('menu-active');
                        document.getElementsByClassName('menu-hider')[0].classList.remove('menu-active');
                    }, menuTimeout)
                }

                if (menuEffect === "menu-push") {
                    var menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
                    if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth + "px)" } }
                    if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth + "px)" } }
                    if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight + "px)" } }
                    if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight + "px)" } }
                }
                if (menuEffect === "menu-parallax") {
                    var menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
                    if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth / 10 + "px)" } }
                    if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth / 10 + "px)" } }
                    if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight / 5 + "px)" } }
                    if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight / 5 + "px)" } }
                }
            }));

            //Closing Menus
            const menuClose = document.querySelectorAll('.close-menu, .menu-hider');
            menuClose.forEach(el => el.addEventListener('click', e => {
                const activeMenu = document.querySelectorAll('.menu-active');
                for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
                for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + 0 + "px)" }
            }));
        }
    }
    menuFunction();


    function activateMenus() {
        const menuActive = document.querySelectorAll('[data-menu-active]')[0];
        if (menuActive) {
            var selectedMenu = menuActive.getAttribute('data-menu-active');
            document.querySelectorAll('#' + selectedMenu)[0].classList.add('nav-item-active');
            if (document.querySelectorAll('#' + selectedMenu)[0].parentNode.getAttribute('class') === "submenu") {
                var subId = '#' + document.querySelectorAll('#' + selectedMenu)[0].parentNode.getAttribute('id')
                var subData = document.querySelectorAll('#' + selectedMenu)[0].parentNode.getAttribute('id')
                var subSize = document.querySelectorAll(subId)[0].children.length;
                var subHeight = document.querySelectorAll(subId)[0].offsetHeight;
                document.querySelectorAll(subId)[0].style.transition = "all 250ms";
                document.querySelectorAll(subId)[0].style.height = (subSize * 50) + 26 + 'px';
                document.querySelectorAll('[data-submenu="' + subData + '"]')[0].classList.add('nav-item-active')
            }
        }

        document.querySelectorAll('[data-submenu]').forEach(function (e) {
            var subID = e.getAttribute('data-submenu');
            var subChildren = document.getElementById(subID).children.length;
            var subtest = e.querySelectorAll('strong')[0];
            subtest.insertAdjacentHTML('beforeend', subChildren)
        });

        var submenuLink = document.querySelectorAll('[data-submenu]');
        submenuLink.forEach(el => el.addEventListener('click', e => {
            el.classList.toggle('nav-item-active');
            var subData = el.getAttribute('data-submenu');
            var subId = '#' + subData
            var subSize = document.querySelectorAll(subId)[0].children.length;
            var subHeight = document.querySelectorAll(subId)[0].offsetHeight;
            if (subHeight === 0) {
                console.log('true')
                document.querySelectorAll(subId)[0].style.transition = "all 250ms";
                document.querySelectorAll(subId)[0].style.height = (subSize * 50) + 26 + 'px';
            } else {
                console.log('false')
                document.querySelectorAll(subId)[0].style.transition = "all 250ms";
                document.querySelectorAll(subId)[0].style.height = "0px";
            }
        }));
    }

}

export default initTemplate;