import { useEffect, useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import FooterBar from "../components/FooterBar";
import AuthService from "../services/auth.service";
import toast from "react-hot-toast";

const EmailVerif = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [valid, setValid] = useState(false);
  const c = searchParams.get("c");

  const validasi = () => {
    const toastx = toast.loading("Sedang memvalidasi akun...");
    AuthService.validasi(c).then(
      (res) => {
        setValid(true);
        toast.dismiss(toastx);
      },
      (error) => {
        toast.dismiss(toastx);
        toast.error("error");
      }
    );
  };

  useEffect(() => {
    validasi();
  });

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div
          className="card bg-20"
          style={{ marginBottom: "-70px", height: "230px" }}
        >
          <div className="card-center text-center">
            <h1 className="color-white font-600 mb-n1 mt-n4 font-32 font-800">
              AULIA HOSPITAL
            </h1>
            <p className="color-white opacity-70">Your Healthcare Solution</p>
          </div>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="/assets/images/pictures/20s.jpg"
          />
        </div>

        <div className="card card-style">
          <div className="content mb-3 text-center">
            {!valid ? (
              <>
                <h4 className="font-600">Mohon tunggu...</h4>
                <p>Kami sedang memvalidasi akun anda.</p>
              </>
            ) : (
              <>
                <h4 className="font-600">Validasi Berhasil!</h4>
                <p>
                  Silahkan tap tombol berikut untuk menuju ke halaman login.
                </p>
                <Link
                  to="/login"
                  className="btn form-control btn-block btn-m mt-4 mb-4 btn-full bg-highlight rounded-sm text-uppercase font-900"
                >
                  Login
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default EmailVerif;
