import axios from "axios";
import authHeader from './auth-header';
import { API_URL } from "./auth.service";

class PendukungService {

  getPendukung() {
    return axios.get(API_URL + "pwa-referensi/list-pendukung", { headers: authHeader() });
  }

  getProvinsi() {
    return axios.get(API_URL + "pwa-referensi/get-provinsi", { headers: authHeader() });
  }

  getKota(idprovinsi) {
    return axios.get(API_URL + "pwa-referensi/get-kabupaten", { headers: authHeader(), params: { id_provinsi: idprovinsi } });
  }

  getKecamatan(idkota) {
    return axios.get(API_URL + "pwa-referensi/get-kecamatan", { headers: authHeader(), params: { id_kabupaten: idkota } });
  }

  getKelurahan(idkec) {
    return axios.get(API_URL + "pwa-referensi/get-kelurahan", { headers: authHeader(), params: { id_kecamatan: idkec } });
  }

  getGeneralConsent() {
    return axios.get(API_URL + "pwa-referensi/general-consent", { headers: authHeader() })
  }

  getPoliklinik() {
    return axios.get(API_URL + "pwa-referensi/get-poli", { headers: authHeader() });
  }

  getDokter(id) {
    return axios.get(API_URL + "pwa-referensi/dokter-by-poli", { headers: authHeader(), params: { id_poli: id } });
  }

  getHistoryPerjanjian(id) {
    return axios.get(API_URL + "pwa-referensi/history-antrean", { headers: authHeader(), params: { id: id } });
  }

  getPerjanjianAktif() {
    return axios.get(API_URL + "pwa-referensi/cek-kode-booking", { headers: authHeader()});
  }

  getJadwalDokter() {
    return axios.get(API_URL + "pwa-referensi/jadwal-dokter", { headers: authHeader()});
  }

  getJadwalByDokter(iddokter) {
    return axios.get(API_URL + "pwa-referensi/get-jadwal-dokter",
      {
        headers: authHeader(),
        params: { id: iddokter }
      });
  }

  getDetailPaketMcu(idpaket) {
    return axios.get(API_URL + "pwa-referensi/get-detail-paket",
      {
        headers: authHeader(),
        params: { id: idpaket }
      });
  }

  cekJadwalDokter(data) {
    return axios.get(API_URL + "pwa-referensi/get-jadwal-praktek",
      {
        headers: authHeader(),
        params: { p: data.idpoli, d: data.iddokter, tg: data.tgl }
      });
  }

  contactUs(data) {
    return axios.post(API_URL + "form-pengaduan/form-pengaduan", { encodedData: data }, { headers: authHeader() });
  }

  gambarSlider() {
    return axios.get(API_URL + "pwa-referensi/list-slider", { headers: authHeader() });
  }

}

export default new PendukungService();