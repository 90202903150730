import { useEffect, useState, Fragment } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import FooterBar from "../components/FooterBar";
import pendukungService from "../services/pendukung.service";
import toast from "react-hot-toast";
import ShapeRound from "../components/ShapeRound";
import Lottie from "react-lottie-player";
import lottieJson from "../lottie/73061-search-not-found.json";
import PasienService from "../services/pasien.service";
import PerjanjianService from "../services/perjanjian.service";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const Riwayat = () => {
  // const [loading, setLoading] = useState(false);
  const [riwayat, setRiwayat] = useState([]);
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
  const navigate = useNavigate();
  const [isError, setError] = useState(false);
  const [idProfil, setIdProfil] = useState("");
  const [profil, setProfil] = useState([]);
  const [idperjanjian, setIdPerjanjian] = useState("");

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [showKonfirmHapus, setShowKonfirmHapus] = useState(false);
  const handleCloseKonfirmHapus = () => setShowKonfirmHapus(false);
  const handleShowKonfirmHapus = (id) => {
    setIdPerjanjian(id);
    setShowKonfirmHapus(true);
  };
  
  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const getRiwayat = (val) => {
    setIdProfil(val);
    const toastId = toast.loading("Mohon tunggu, sedang mengambil data...");
    // setLoading(true);
    pendukungService.getHistoryPerjanjian(val).then(
      (res) => {
        let result = decryptData(token, res.data);
        for (let i = 0; i < result.length; i++) {
          result[i].id_perjanjian = encryptData(token, result[i].id_perjanjian);
        }
        setRiwayat(result);
        // setLoading(false);
        toast.dismiss(toastId);
      },
      (error) => {
        toast.dismiss(toastId);
        if (error.response.status === 500) setError(true);
        if (error.response.status === 401 || error.response.status === 402)
          localStorage.removeItem("aulia_pwa");
          navigate("/login");
      }
    );
  };

  const getProfil = () => {
    PasienService.getPasien().then((res) => {
      let result = decryptData(token, res.data);
      for (let i = 0; i < result.length; i++) {
        result[i].id_pasien = encryptData(token, result[i].id_pasien);
      }
      setProfil(result);
      getRiwayat(result[0].id_pasien);
      if (result.length === 0) {
        navigate("/profil");
        toast.error("Silahkan tambah profil pasien terlebih dahulu.");
      }
    });
  };

  const batalkan = () => {
    const tbp = toast.loading("Mohong tunggu....");
    PerjanjianService.batalPerjanjian({ id_perjanjian: idperjanjian }).then(
      (res) => {
        getProfil();
        toast.success("Perjanjian berhasil dibatalkan!");
        toast.dismiss(tbp);
        handleCloseKonfirmHapus();
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        toast.dismiss(tbp);
        toast.error(resMessage);
      }
    );
  };

  useEffect(() => {
    setCurrentUser(currentUser);
    if (!currentUser) {
      toast.error("Untuk mengakses halaman ini, anda harus login terlebih dahulu."
      );
      localStorage.removeItem("aulia_pwa");
      navigate("/login");
    } else {
      // getRiwayat();
      getProfil();
    }
  }, []);

  return (
    <>
      <FooterBar />

      <div className="page-content">
        <div className="page-title page-title-small">
          <h2>Riwayat Perjanjian</h2>
        </div>

        <ShapeRound />

        <div>
          {!riwayat || riwayat.length === 0 ? (
            <div className="card card-style">
              <div className="content">
                <label className="font-600">Profil Pasien:</label>
                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field">
                  <select
                    name="id_pasien"
                    value={idProfil}
                    onChange={(e) => getRiwayat(e.target.value)}
                  >
                    {profil.map((a) => (
                      <Fragment key={a.id_pasien}>
                        <option value={a.id_pasien}>{a.nama_lengkap}</option>
                      </Fragment>
                    ))}
                  </select>
                </div>
                <div className="d-flex">
                  <div>
                    <h2 className="mb-0 pt-1">Belum ada riwayat.</h2>

                    <p>
                      Untuk melakukan perjanjian temu atau pendaftaran ke
                      poliklinik, silahkan klik tombol dibawah ini.
                    </p>
                    <Link
                      to="/layanan"
                      className="mt-2 btn form-control btn-m btn-full rounded-s bg-highlight text-uppercase font-900"
                    >
                      BUAT PERJANJIAN
                    </Link>
                    <Lottie loop animationData={lottieJson} play />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <div className="card card-style">
                <div className="content">
                  <label className="font-600">Profil Pasien:</label>
                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field">
                    <select
                      name="id_pasien"
                      value={idProfil}
                      onChange={(e) => getRiwayat(e.target.value)}
                    >
                      {profil.map((a) => (
                        <Fragment key={a.id_pasien}>
                          <option value={a.id_pasien}>{a.nama_lengkap}</option>
                        </Fragment>
                      ))}
                    </select>
                  </div>
                </div>
              </div>

              {riwayat.map((r) => (
                <div
                  className="card card-style rounded-m shadow-xl mb-3"
                  style={{ height: 220 }}
                  key={r.kode_booking}
                >
                  <div className="card-top mt-4 ms-3">
                    <h4>
                      <span className="btn-secondary">NO. BOOKING:</span>{" "}
                      <span className="color-highlight">{r.kode_booking}</span>
                    </h4>
                    <h4>
                      NO. ANTRIAN: {r.nomor_antrean} |{" "}
                      <span className="">{r.status}</span>
                    </h4>{" "}
                    <p className="font-10 mt-2 mb-n2">
                      <i className="fa fa-id-card" />{" "}
                      <strong>{r.jenis_pelayanan}</strong>
                      <i className="ms-3 fa fa-map-marker-alt" />{" "}
                      <strong className="color-highlight">
                        POLI {r.nama_poli}
                      </strong>
                    </p>
                    <p className="font-10 mt-2 mb-n1">
                      <i className="far fa-calendar" /> {r.tanggal_periksa}
                      <i className="ms-3 far fa-clock" /> {r.jam_praktek}
                    </p>
                    <p className="font-10">
                      <i className="fa fa-user-alt" /> {r.nama_dokter}
                      <br />
                      <strong className="text-primary font-12">
                        Estimasi dilayani: {r.estimasi_dilayani}
                      </strong>
                      <br />
                      <span className="text-danger font-11">
                        {r.keterangan}
                      </span>
                    </p>
                  </div>
                  <div className="card-center me-3">
                    {r.status === "Belum Dilayani" && (
                      <button
                        onClick={() => handleShowKonfirmHapus(r.id_perjanjian)}
                        className="float-end ms-1 bg-danger btn btn-xs text-uppercase font-900 rounded-xl font-10"
                      >
                        Batalkan
                      </button>
                    )}
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
      </div>

      <Modal
        show={showKonfirmHapus}
        onHide={handleCloseKonfirmHapus}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>Anda ingin membatalkan perjanjian ini?</Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl"
            onClick={handleCloseKonfirmHapus}
          >
            Batal
          </Button>
          <Button
            className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl btn-danger"
            onClick={batalkan}
          >
            Ya, Hapus
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Riwayat;
