import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import FooterBar from "../components/FooterBar";
import PendukungService from "../services/pendukung.service";
import ShapeRound from "../components/ShapeRound";
import toast from 'react-hot-toast';

const JadwalDokter = () => {

  const [jadwal, setJadwal] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [q, setQ] = useState("");
  const [searchParam] = useState(["nama_lengkap"]);

  const getData = () => {
    const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
    PendukungService.getJadwalDokter().then((res) => {
      setJadwal(res.data);
      setIsLoaded(true);
      toast.dismiss(toastId);
    }, error => {
      toast.dismiss(toastId);
    })
  }

  const filterData = () => {
    return jadwal.filter((item) => {
      return searchParam.some((newItem) => {
        return (
          item[newItem]
            .toString()
            .toLowerCase()
            .indexOf(q.toLowerCase()) > -1
        );
      });
    });
  }

  useEffect(() => {
    getData();
  }, [])

  return (
    <>
      <FooterBar />

      <div className="page-content">

        <div className="page-title page-title-small">
          <h2>
            {/* <a onClick={() => navigate("/")}>
              <i className="fa fa-arrow-left" />
            </a> */}

            Jadwal Praktek Dokter

          </h2>
        </div>

        <ShapeRound />

        <div className="card card-style">
          <div className="content">

            <div className="search-box search-color bg-highlight rounded-s mb-0">
              <i className="fa fa-search" />
              <input
                type="text"
                className="border-0"
                placeholder="Cari nama dokter... "
                onChange={(e) => setQ(e.target.value)}
                value={q}
              />
            </div>

          </div>

          <Accordion defaultActiveKey="0">

            {filterData(jadwal).map((dd) => (

              <Accordion.Item eventKey={dd.id} key={dd.id}>
                <Accordion.Header>{dd.nama_lengkap}</Accordion.Header>
                <Accordion.Body>

                  <table
                    className="table table-borderless text-center rounded-sm shadow-l"
                    style={{ overflow: "hidden" }}
                  >
                    <thead>
                      <tr className="bg-gray-light">
                        <th scope="col" className="color-theme py-3 font-14">
                          Hari
                        </th>
                        <th scope="col" className="color-theme py-3 font-14">
                          Poli
                        </th>
                        <th scope="col" className="color-theme py-3 font-14">
                          Jam
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {dd.jadwal.map((j) => (
                        <tr>
                          <th scope="row">{j.hari}</th>
                          <td className="color-green-dark">{j.nama_departemen}</td>
                          <td>
                            {j.jam_mulai} - {j.jam_selesai}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                </Accordion.Body>
              </Accordion.Item>

            ))}

          </Accordion>
        </div>
      </div>
    </>


  )
}

export default JadwalDokter