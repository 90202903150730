import { Link } from "react-router-dom"

const BlockedPage = () => {
  return (

    <>
      
      <div className="page-content">
        <div className="page-title page-title-small">
          <h2>

            Oops!

          </h2>
        </div>
        <div className="card header-card shape-rounded" style={{ height: "180px" }}>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div className="card-bg preload-img" />
        </div>

        <div className="card card-style text-center">
          <div className="content py-5">
            <h1>
              <i className="fa fa-exclamation-triangle color-red-dark fa-4x" />
            </h1>
           
            <h4 className="text-uppercase pb-3">MOHON MAAF 😔</h4>
            <p className="boxed-text-l">
              Silahkan daftar/ masuk terlebih dahulu untuk mengakses halaman ini. <br/>
            </p>
            <div className="row mb-0">
              <div className="col-6 pe-0">
                <Link
                  to="/"
                  className="back-button btn btn-m btn-center-s bg-highlight rounded-sm font-700 text-uppercase"
                >
                 Kembali
                </Link>
              </div>
              <div className="col-6 ps-0">
                <Link
                  to="/login"
                  className="back-button btn btn-m btn-center-s bg-highlight rounded-sm font-700 text-uppercase"
                >
                  Masuk
                </Link>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default BlockedPage