import FooterBar from "../components/FooterBar";
import ShapeRound from "../components/ShapeRound";
import { Form, Field } from 'react-final-form'
import PendukungService from "../services/pendukung.service";
import toast from 'react-hot-toast';
import { useNavigate } from "react-router-dom";

import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const ContactUs = () => {
  const navigate = useNavigate();

  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const onSubmit = (values) => {
    values = encryptData(token, values);
    PendukungService.contactUs(values).then((res) => {
      toast.success("Terima kasih atas kritik/ sarannya.");
      navigate("/")
    }, error => {
      console.log(error);
    })
  }
  const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className="text-danger">{error}</span> : null
      }
    />
  )
  const required = value => (value ? undefined : 'Wajib diisi');
  return (
    <>
      <FooterBar />
      <div className="page-content">

        <div className="page-title page-title-small">
          <h2>Kritik & Saran</h2>
        </div>
        <ShapeRound />

        <div className="card card-style contact-form">

          <div className="content">

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>

                  <div className="mt-3 input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <label className="font-400 font-13">
                      Nama Anda:
                    </label>
                    <Field
                      name="nama"
                      component="input"
                      type="text"
                      validate={required}
                    />
                    <Error name="nama" />
                  </div>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <label className="font-400 font-13">
                      No. Handphone:
                    </label>
                    <Field
                      name="no_hp"
                      component="input"
                      type="text"
                      validate={required}
                    />
                    <Error name="no_hp" />
                  </div>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <label className="font-400 font-13">
                      Perihal:
                    </label>
                    <Field name="layanan" component="select" validate={required}>
                      <option>Pilih</option>
                      <option value="Bagian Pendaftaran ( Admission )">Bagian Pendaftaran ( Admission )</option>
                      <option value="Bagian Dokter ( Pelayanan Medis )">Bagian Dokter ( Pelayanan Medis )</option>
                      <option value="Bagian Perawat ( Keperawatan )">Bagian Perawat ( Keperawatan )</option>
                      <option value="Bagian Kasir ( Keuangan )">Bagian Kasir ( Keuangan )</option>
                      <option value="Bagian Farmasi ( Penunjang Medis ) ">Bagian Farmasi ( Penunjang Medis )</option>
                      <option value="Bagian Makanan/Minuman ( Penunjang Medis )">Bagian Makanan/Minuman ( Penunjang Medis )</option>
                      <option value="Bagian Sales/Marketing">Bagian Sales/Marketing</option>
                      <option value="Bagian Informasi ( Humas )">Bagian Informasi ( Humas )</option>
                      <option value="Bagian Security/Kebersihan ( Umum )">Bagian Security/Kebersihan ( Umum )</option>
                    </Field>

                    <Error name="layanan" />
                  </div>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <Field
                      name="catatan"
                      component="textarea"
                      type="textarea"
                      validate={required}
                    />
                    <label className="font-400 font-13">
                      Ceritakan Pengalaman/ Kritik/ Saran Anda:
                    </label>
                    <Error name="catatan" />
                  </div>

                  <button type="submit" className="btn form-control btn-full btn-m rounded-s text-uppercase font-900 shadow-xl bg-highlight">Kirim</button>

                </form>
              )}
            />

          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs