import toast from "react-hot-toast";
import React, { useState } from "react";
import AuthService from "../services/auth.service";
import { Link, useNavigate } from "react-router-dom";
import FooterBar from "../components/FooterBar";

const ForgotPassword = () => {
  const [nik, setNik] = useState("");
  const navigate = useNavigate();

  const Auth = (e) => {
    e.preventDefault();
    const toastId = toast.loading("Mohon tunggu...");
    AuthService.sendLinkResetPassword(nik).then(
      (respon) => {
        toast.dismiss(toastId);
        toast(respon.data, {
          icon: "👏",
        });
      },
      (error) => {
        toast.dismiss(toastId);
        toast.error(error.response.data);
      }
    );
  };

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div
          className="card bg-20"
          style={{ marginBottom: "-70px", height: "230px" }}
        >
          <div className="card-center text-center">
            <h1 className="color-white font-600 mb-n1 mt-n4 font-32 font-800">
              AULIA HOSPITAL
            </h1>
            <p className="color-white opacity-70">Your Healthcare Solution</p>
          </div>
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div
            className="card-bg preload-img"
            data-src="/assets/images/pictures/20s.jpg"
          />
        </div>

        <div className="card card-style">
          <div className="content mb-0">
            <div className="d-flex">
              <div className="me-auto">
                <h4 className="font-600">Lupa Password</h4>
                <p className="font-11 mt-n2 mb-3">
                  Silahkan isi NIK dan tautan konfirmasi akan dikirimkan ke
                  email anda.
                </p>
              </div>
              <div className="ms-auto">
                <i
                  data-feather="user"
                  data-feather-line="1"
                  data-feather-size="40"
                  data-feather-color="blue-dark"
                  data-feather-bg="blue-fade-light"
                ></i>
              </div>
            </div>
            <div className="divider"></div>

            <form onSubmit={Auth}>
              <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                <input
                  type="name"
                  className="form-control validate-name"
                  value={nik}
                  onChange={(e) => setNik(e.target.value)}
                />
                <label
                  htmlFor="form1"
                  className="color-highlight font-400 font-13"
                >
                  NIK
                </label>
                <i className="fa fa-times disabled invalid color-red-dark" />
                <i className="fa fa-check disabled valid color-green-dark" />
                <em>(required)</em>
              </div>

              <button
                type="submit"
                className="btn form-control btn-block btn-m mt-4 mb-4 btn-full bg-highlight rounded-sm text-uppercase font-900"
              >
                Kirim Tautan
              </button>
            </form>

            <div className="d-flex">
              <div className="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-start">
                <Link to="/registrasi" className="color-theme">
                  Daftar Akun
                </Link>
              </div>
              <div className="w-50 font-11 pb-2 color-theme opacity-60 pb-3 text-end">
                <Link className="color-theme" to="/login">
                  Masuk
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword;
