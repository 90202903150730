import { bearier_token } from './auth.service';

export default function authHeader() {
  const user = JSON.parse(localStorage.getItem('aulia_pwa'));

  if (user && user.token) {
    return { Authorization: 'Bearer ' + user.token, nik: user.nik };
  } else {
    return { Authorization: 'Bearer ' + bearier_token };
  }
}