import moment from "moment";
import 'moment-timezone'

export function convertDate(date) {
    var jakarta = moment.tz(date, "Asia/Jakarta");
    return jakarta.format();
}

export function konversiTgl(date){
    return moment(date).format('DD MMMM YYYY');
}