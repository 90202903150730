import { Link } from "react-router-dom";

const MenuHome = ({ userReady }) => {
  return (
    <div>
      {/* <Link to="/layanan"
                className="card card-style mb-3 d-flex"
                style={{ height: 100 }}
            >
                <div className="d-flex justify-content-between">
                    <div className="ps-3 ms-1 align-self-center">
                        <h4 className="font-600 mb-0 pt-4 mt-1">Layanan Poliklinik</h4>
                        <p className="color-highlight mt-n1 font-11">Temukan layanan terbaik kami</p>
                    </div>
                    <div className="pe-3 align-self-center">
                        <img src="/png/advice.png" width={70} />
                    </div>
                </div>
            </Link> */}

      <div className="row text-center mb-2">
        {/* <Link to="jadwal-dokter" className="col-6 pe-2">
                    <div className="card card-style me-0  pt-4 mb-3">
                        <h1 className="center-text pt-2">
                            <img src="/png/schedule.png" width={50} />
                        </h1>
                        <h4 className="color-theme font-600">Jadwal Dokter</h4>
                        <p className="font-10 opacity-30 mb-1">&nbsp;</p>
                    </div>
                </Link> */}
        <Link to="/layanan" className="col-6 pe-2">
          <div className="card card-style me-0  pt-4 mb-3">
            <h1 className="center-text pt-2">
              <img src="/png/doctor.png" width={50} />
            </h1>
            <h4 className="color-theme font-600">Praktek Dokter</h4>
            <p className="font-10 opacity-30 mb-1">&nbsp;</p>
          </div>
        </Link>
        <Link to="/paket" className="col-6 ps-2">
          <div className="card card-style ms-0  pt-4 mb-3">
            <h1 className="center-text pt-2">
              <img src="/png/medical-report.png" width={50} />
            </h1>
            <h4 className="color-theme font-600">Paket MCU</h4>
            <p className="font-10 opacity-30 mb-1">&nbsp;</p>
          </div>
        </Link>
        <Link to="/bmi" className="col-6 pe-2">
          <div className="card card-style me-0  pt-4 mb-3">
            <h1 className="center-text pt-2">
              <img src="/png/body-mass-index.png" width={50} />
            </h1>
            <h4 className="color-theme font-600">Body Mass Index</h4>
            <p className="font-10 opacity-30 mb-1">&nbsp;</p>
          </div>
        </Link>
        <Link to="contact-us" className="col-6 ps-2">
          <div className="card card-style ms-0  pt-4 mb-3">
            <h1 className="center-text pt-2">
              <img src="/png/mailbox.png" width={50} />
            </h1>
            <h4 className="color-theme font-600">Kritik & Saran</h4>
            <p className="font-10 opacity-30 mb-1">&nbsp;</p>
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MenuHome;
