import FooterBar from "../components/FooterBar";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PendukungService from "../services/pendukung.service";
import toast from 'react-hot-toast';
import ShapeRound from "../components/ShapeRound";
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const Poliklinik = () => {

    const [poli, setPoli] = useState([]);
    const [q, setQ] = useState("");
    const [searchParam] = useState(["poli"]);
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem('aulia_pwa'));
    const token = user ? user.token : bearier_token;

    const getPoli = () => {
        const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
        PendukungService.getPoliklinik().then((res) => {
            let result = decryptData(token, res.data);
            for (let i = 0; i < result.length; i++) {
                result[i].id_poli = encryptData(token, result[i].id_poli);
            }
            setPoli(result);
            toast.dismiss(toastId);
        }, error => {
            toast.dismiss(toastId);
            console.log(error);
        })
    }

    const filterData = () => {
        return poli.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    useEffect(() => {
        getPoli();

    }, []);

    const listDokter = (idpoli) => {
        navigate('/dokter', { state: { idpoli: idpoli } });
    }

    return (
        <div>
            <FooterBar />
            <div className="page-content">

                <div className="page-title page-title-small">
                    <h2>
                        <a onClick={() => navigate("/")}>
                            <i className="fa fa-arrow-left" />
                        </a>
                        Poliklinik
                    </h2>
                </div>

                <ShapeRound />

                <div>
                    <div className="card card-style">
                        <div className="content">
                            {/* <h4 className="font-600 mb-2">Pilih Layanan</h4> */}
                            <div className="search-box search-color bg-highlight rounded-s mb-0">
                                <i className="fa fa-search" />
                                <input
                                    type="text"
                                    className="border-0"
                                    placeholder="Cari Pelayanan... "
                                    onChange={(e) => setQ(e.target.value)}
                                    value={q}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="card bg-transparent">
                        <div className="row mt-3">

                            {filterData(poli).map((p, i) => (

                                <div className="col-6"
                                    key={i}
                                    onClick={() => listDokter(p.id_poli)}
                                    style={{ cursor: "pointer" }}>
                                    <div className="card card-style text-center" style={{ height: 150 }}>
                                        <div className="text-center">
                                        <img className="m-3" width={100} src={p.gambar}></img>
                                        </div>
                                       
                                        <h5 className="card-title"></h5>
                                        <div className="card-bottom">
                                            <h5 className="boxed-text-xl font-12">
                                                {p.poli}
                                            </h5>
                                        </div>
                                        <div className="card-overlay bg-gradient-fade" />
                                    </div>
                                </div>

                            ))}

                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default Poliklinik;