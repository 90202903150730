
const ShapeRound = () => {
  return (
    <div className="card header-card shape-rounded" style={{ height: "150px" }}>
      <div className="card-overlay bg-highlight opacity-95" />
      <div className="card-overlay dark-mode-tint" />
    </div>
  )
}

export default ShapeRound