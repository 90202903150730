import axios from "axios";
import authHeader from "./auth-header";
import { decryptData } from "../helpers/Secure";

// const API_URL = "http://100.100.100.227/irhis-mobile/web/";
export const API_URL =
  "https://rest.auliahospital.co.id:7777/irhis_mobile/web/";
// export const API_URL = "http://localhost/irhis_mobile/web/";
export const bearier_token = "UIVV0v2crJe3R8kVcJE0G9sX0adc9H4gHC1fhYeXUhyIHPPzXBjHHUtvkLPc16BDJ21IjVufLkC1mLxYzjoIxPFFE9ZFzewAi5iudJgPpU6ICGwhVQxR6AORuFjttcMqSvkL7PLpbJ3mrkaYfygyvzpvhvk3hdhzp5aaWJff7Wa6Z7H84ST4zyU3Lfap1W9YdbkmOUZHXZkKjvoE3F9ajUwFtB7GSfJ5hW8wsIU6gqA4JuK2sKYfe48vljkth0wAeBp2VIkkwBRCMivFSlMjJd7Af8l2qjDTMwpPrI8RbC2UItd84Hz5Pd52R30brS73y35fbfscTiowiIHf2fmvdiSDbiBfP7Se7AkbKCaiWixZIHrxadcRtD25KyUVqHc75iqxrtAjgXJY0zbGJfcXA4wJgW6Z50vPm8wRPcFyL97Vt2CkiE2uVZTdixcYzYtOT63GCoZjH7I5SoEH6QMKzwKmx2Yu7l21l1qM7v1HTuI1werpkpQQPbm2mrR7K9Wv";

class AuthService {
  login(nik, password) {
    return axios
      .post(API_URL + "auth/login-pwa", {
        nik,
        password,
      }, { headers: authHeader() })
      .then((response) => {
        const result = decryptData(bearier_token, response.data);

        if (result.token) {
          localStorage.setItem("aulia_pwa", JSON.stringify(result));
        }

        return result;
      });
  }

  logout() {
    localStorage.removeItem("aulia_pwa");
  }

  register(nik, email, password, repassword) {
    return axios.post(API_URL + "auth/create-user-mobile", {
      nik,
      email,
      password,
      repassword,
    });
  }

  getCurrentUser() {
    return JSON.parse(localStorage.getItem("aulia_pwa"));
  }

  checkToken() {
    return axios.get(API_URL + "auth/check-user", { headers: authHeader() });
  }

  sendLinkResetPassword(nik) {
    return axios.post(API_URL + "auth/lupa-password-pwa", { nik: nik });
  }

  sendResetPassword(data) {
    return axios.post(API_URL + "auth/update-password-pwa", data);
  }

  validasi(data) {
    return axios.post(API_URL + "auth/verifikasi-email-user", { var: data });
  }
}

export default new AuthService();
