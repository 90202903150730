import Wizard from "../components/Wizard";
import { Field } from "react-final-form";
import React, { useEffect, useState } from "react";
import FooterBar from "../components/FooterBar";
import { Link, useNavigate } from "react-router-dom";
import PendukungService from "../services/pendukung.service";
import PasienService from "../services/pasien.service";
import parse from "html-react-parser";
import toast from "react-hot-toast";
import InputMask from "react-input-mask";

const AddPasien = () => {
  const navigate = useNavigate();

  const [provs, setProv] = useState([]);
  const [kabs, setKab] = useState([]);
  const [kecs, setKec] = useState([]);
  const [kels, setKel] = useState([]);
  const [agamas, setAgamas] = useState([]);
  const [pendidikans, setPendidikans] = useState([]);
  const [pekerjaans, setPekerjaans] = useState([]);
  const [golDarahs, setGolDarahs] = useState([]);
  const [statusKawins, setStatusKawins] = useState([]);
  const [id_provinsi, setIdProvinsi] = useState([]);
  const [id_kabupaten, setIdKabupaten] = useState([]);
  const [id_kecamatan, setIdKecamatan] = useState([]);
  const [generalCons, setGeneralCons] = useState("");
  // const [startDate, setStartDate] = useState(new Date());
  const [tanggalLahir, setTanggalLahir] = useState("");

  const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  const onSubmit = async (values) => {
    const toastId = toast.loading("Mohon tunggu...");
    await sleep(300);
    PasienService.daftarPasienBaru(
      values.pemilik_profil,
      values.nik,
      values.namaLengkap,
      values.jenisKelamin,
      values.tempatLahir,
      // values.tanggalLahir,
      tanggalLahir,
      values.noHp,
      values.email,
      values.agama,
      values.golDarah,
      values.pendidikan,
      values.pekerjaan,
      values.alamatDomisili,
      values.alamat,
      id_provinsi,
      id_kabupaten,
      id_kecamatan,
      values.kelurahan,
      values.statusKawin,
      values.nama_kerabat,
      values.hubungan_kerabat,
      values.no_hp_kerabat,
      values.jenis_kelamin_kerabat
    ).then(
      (res) => {
        navigate("/profil");
        toast.dismiss(toastId);
      },
      (error) => {
        toast.dismiss(toastId);
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        if (error.response.status === 401) {
          localStorage.removeItem("aulia_pwa");
          navigate("/login");
        }
        if (error.response.status === 400) toast.error(resMessage);
      }
    );
  };

  const Error = ({ name }) => (
    <Field
      name={name}
      subscribe={{ touched: true, error: true }}
      render={({ meta: { touched, error } }) =>
        touched && error ? <span className="text-danger">{error}</span> : null
      }
    />
  );

  const required = (value) => (value ? undefined : "Wajib diisi");

  const getProv = () => {
    PendukungService.getProvinsi().then(
      (res) => {
        setProv(res.data);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log(resMessage);
      }
    );
  };

  const getKab = (idprov) => {
    setIdProvinsi(idprov);
    setKec([]);

    PendukungService.getKota(idprov).then(
      (res) => {
        setKab(res.data);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log(resMessage);
      }
    );
  };

  const getKec = (idkota) => {
    setIdKabupaten(idkota);
    setKel([]);
    PendukungService.getKecamatan(idkota).then(
      (res) => {
        setKec(res.data);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log(error);
      }
    );
  };

  const getKel = (idkec) => {
    setIdKecamatan(idkec);
    PendukungService.getKelurahan(idkec).then(
      (res) => {
        setKel(res.data);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log(error);
      }
    );
  };

  const getPendukung = () => {
    PendukungService.getPendukung().then(
      (res) => {
        setAgamas(res.data.agama);
        setPendidikans(res.data.pendidikan);
        setPekerjaans(res.data.pekerjaan);
        setGolDarahs(res.data.golongan_darah);
        setStatusKawins(res.data.status_perkawinan);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        if (error.response.status === 401) {
          localStorage.removeItem("aulia_pwa");
          navigate("/login");
        }
      }
    );
  };

  const getGeneralConsent = () => {
    PendukungService.getGeneralConsent().then(
      (res) => {
        setGeneralCons(res.data.toString());
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        console.log(error);
      }
    );
  };

  const cekNik = (e) => {
    PasienService.cekPasienByNik(e).then(
      (response) => {
        return false;
      },
      (error) => {
        if (error.response.status === 400)
          toast(error.response.data.msg, {
            duration: 6000,
            style: {
              borderRadius: "10px",
              background: "#EF5350",
              color: "#fff",
            },
          });
      }
    );
  };

  useEffect(() => {
    getPendukung();
    getProv();
    getGeneralConsent();
  }, []);

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div className="page-title page-title-small">
          <h2>
            <Link to="/profil">
              <i className="fa fa-arrow-left" />
            </Link>
            Form Data Pasien Baru
          </h2>
        </div>
        <div
          className="card header-card shape-rounded"
          style={{ height: "150px" }}
        >
          <div className="card-overlay bg-highlight opacity-95" />
          <div className="card-overlay dark-mode-tint" />
          <div className="card-bg preload-img" />
        </div>

        <Wizard onSubmit={onSubmit}>
          <Wizard.Page>
            <div className="card card-style">
              <div className="content pt-3">
                <h1>General Consent</h1>

                {parse(generalCons)}

                <div className="form-check">
                  <Field
                    name="isSetuju"
                    component="input"
                    type="checkbox"
                    id="issetuju"
                    className="form-check-input"
                    validate={required}
                  />
                  <label className="form-check-label" htmlFor="issetuju">
                    Saya menyetujui
                  </label>
                </div>
              </div>
            </div>
          </Wizard.Page>

          <Wizard.Page>
            <div className="card card-style">
              <div className="content pt-3">
                <h1>Data Personal</h1>
                <p>Silahkan isi form berikut dengan benar dan lengkap:</p>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="pemilik_profil"
                    component="select"
                    validate={required}
                  >
                    <option></option>
                    <option value="diri_sendiri">Diri Sendiri</option>
                    <option value="orang_lain">Orang Lain</option>
                  </Field>
                  <Error name="pemilik_profil" />
                  <label className="color-highlight font-400 font-13">
                    Pemilik Profil
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  {/* <Field
                    name="nik"
                    component="input"
                    type="text"
                    validate={required}
                  /> */}
                  <Field name="nik" validate={required}>
                    {({ input, meta }) => (
                      <div>
                        <input
                          {...input}
                          type="number"
                          onBlur={(e) => cekNik(e.target.value)}
                        />
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>

                  <Error name="nik" />
                  <label className="color-highlight font-400 font-13">
                    NIK
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="namaLengkap"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error name="namaLengkap" />
                  <label className="color-highlight font-400 font-13">
                    Nama Lengkap
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="jenisKelamin"
                    component="select"
                    validate={required}
                  >
                    <option></option>
                    <option value="Laki-laki">Laki-laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </Field>
                  <Error name="jenisKelamin" />
                  <label className="color-highlight font-400 font-13">
                    Jenis Kelamin
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="tempatLahir"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error name="tempatLahir" />
                  <label className="color-highlight font-400 font-13">
                    Tempat Lahir
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  {/* <DatePicker
                    selected={startDate}
                    dateFormat="dd/MM/yyyy"
                    onChange={(date) => setStartDate(date)} /> */}
                  <InputMask
                    mask="99-99-9999"
                    placeholder="dd-mm-yyyy"
                    value={tanggalLahir}
                    onChange={(e) => setTanggalLahir(e.target.value)}
                  ></InputMask>
                  <label className="color-highlight font-400 font-13">
                    Tanggal Lahir
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="noHp"
                    component="input"
                    type="number"
                    validate={required}
                  />
                  <Error name="noHp" />
                  <label className="color-highlight font-400 font-13">
                    No. HP/ Whatsapp
                  </label>
                </div>
              </div>
            </div>
          </Wizard.Page>

          <Wizard.Page>
            <div className="card card-style">
              <div className="content pt-3">
                <h1>Informasi Pendukung</h1>
                <p>Silahkan isi form berikut dengan benar dan lengkap:</p>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="email" component="input" type="email" />
                  <Error name="email" />
                  <label className="color-highlight font-400 font-13">
                    Email
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="agama" component="select" validate={required}>
                    <option />
                    {agamas.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                  </Field>
                  <Error name="agama" />
                  <label className="color-highlight font-400 font-13">
                    Agama
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="golDarah" component="select" validate={required}>
                    <option />
                    {golDarahs.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                  </Field>
                  <Error name="golDarah" />
                  <label className="color-highlight font-400 font-13">
                    Golongan Darah
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="pendidikan"
                    component="select"
                    validate={required}
                  >
                    <option />
                    {pendidikans.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                  </Field>
                  <Error name="pendidikan" />
                  <label className="color-highlight font-400 font-13">
                    Pendidikan
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="pekerjaan"
                    component="select"
                    validate={required}
                  >
                    <option />
                    {pekerjaans.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                  </Field>
                  <Error name="pekerjaan" />
                  <label className="color-highlight font-400 font-13">
                    Pekerjaan
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="statusKawin"
                    component="select"
                    validate={required}
                  >
                    <option />
                    {statusKawins.map((a) => (
                      <option key={a.id} value={a.id}>
                        {a.value}
                      </option>
                    ))}
                  </Field>
                  <Error name="statusKawin" />
                  <label className="color-highlight font-400 font-13">
                    Status Perkawinan
                  </label>
                </div>
              </div>
            </div>
          </Wizard.Page>

          <Wizard.Page>
            <div className="card card-style">
              <div className="content pt-3">
                <h1>Informasi Tempat Tinggal</h1>
                <p>Silahkan isi form berikut dengan benar dan lengkap:</p>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="alamatDomisili"
                    component="textarea"
                    type="textarea"
                    validate={required}
                  />
                  <Error name="alamatDomisili" />
                  <label className="color-highlight font-400 font-13">
                    Alamat Domisili
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="alamat"
                    component="textarea"
                    type="textarea"
                    validate={required}
                  />
                  <Error name="alamat" />
                  <label className="color-highlight font-400 font-13">
                    Alamat (sesuai kartu identitas)
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="provinsi">
                    {({ select, meta }) => (
                      <div>
                        <select
                          {...select}
                          onChange={(e) => getKab(e.target.value)}
                        >
                          <option>Pilih</option>
                          {provs.map((a) => (
                            <option value={a.id}>{a.value}</option>
                          ))}
                        </select>
                        {meta.error && meta.touched && (
                          <span>{meta.error}</span>
                        )}
                      </div>
                    )}
                  </Field>

                  <label className="color-highlight font-400 font-13">
                    Provinsi (sesuai kartu identitas)
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="kota">
                    {(props) => (
                      <div>
                        <select
                          {...props.select}
                          onChange={(e) => getKec(e.target.value)}
                        >
                          <option>Pilih</option>
                          {kabs.map((a) => (
                            <option value={a.id}>{a.value}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </Field>
                  <Error name="kota" />
                  <label className="color-highlight font-400 font-13">
                    Kota/ Kabupaten (sesuai kartu identitas)
                  </label>
                </div>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="kecamatan">
                    {(kabs) => (
                      <div>
                        <select
                          {...kabs.select}
                          onChange={(e) => getKel(e.target.value)}
                        >
                          <option>Pilih</option>
                          {kecs.map((a) => (
                            <option value={a.id}>{a.value}</option>
                          ))}
                        </select>
                      </div>
                    )}
                  </Field>
                  <Error name="kota" />
                  <label className="color-highlight font-400 font-13">
                    Kecamatan (sesuai kartu identitas)
                  </label>
                </div>

                {/* <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="kecamatan" component="select">
                    <option>Pilih</option>
                    {kecs.map((a) => (
                      <option value={a.id}>{a.value}</option>
                    ))}
                  </Field>
                  <Error name="kecamatan" />
                  <label className="color-highlight font-400 font-13">
                    Kecamatan (sesuai kartu identitas)
                  </label>
                </div> */}

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field name="kelurahan" component="select">
                    <option>Pilih</option>
                    {kels.map((a) => (
                      <option value={a.id}>{a.value}</option>
                    ))}
                  </Field>
                  <Error name="kelurahan" />
                  <label className="color-highlight font-400 font-13">
                    Kelurahan (sesuai kartu identitas)
                  </label>
                </div>
              </div>
            </div>
          </Wizard.Page>

          <Wizard.Page>
            <div className="card card-style">
              <div className="content pt-3">
                <h1>Informasi Kerabat/ Keluarga</h1>
                <p>Silahkan isi form berikut dengan benar dan lengkap:</p>

                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="nama_kerabat"
                    component="input"
                    type="text"
                    validate={required}
                  />
                  <Error name="nama_kerabat" />
                  <label className="color-highlight font-400 font-13">
                    Nama Kerabat
                  </label>
                </div>
                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="jenis_kelamin_kerabat"
                    component="select"
                    validate={required}
                  >
                    <option>Pilih</option>
                    <option value="Laki-laki">Laki-laki</option>
                    <option value="Perempuan">Perempuan</option>
                  </Field>
                  <Error name="jenis_kelamin_kerabat" />
                  <label className="color-highlight font-400 font-13">
                    Jenis Kelamin
                  </label>
                </div>
                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="hubungan_kerabat"
                    component="select"
                    validate={required}
                  >
                    <option>Pilih</option>
                    <option value={1}>Ayah</option>
                    <option value={2}>Ibu</option>
                    <option value={3}>Saudara Laki-laki</option>
                    <option value={4}>Saudara Perempuan</option>
                    <option value={5}>Anak</option>
                    <option value={6}>Diri Sendiri</option>
                    <option value={8}>Istri</option>
                    <option value={9}>Suami</option>
                    <option value={10}>Lainnya</option>
                  </Field>
                  <Error name="hubungan_kerabat" />
                  <label className="color-highlight font-400 font-13">
                    Hubungan dengan Pasien
                  </label>
                </div>
                <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                  <Field
                    name="no_hp_kerabat"
                    component="input"
                    type="number"
                    validate={required}
                  />
                  <Error name="no_hp_kerabat" />
                  <label className="color-highlight font-400 font-13">
                    No. Handphone
                  </label>
                </div>
              </div>
            </div>
          </Wizard.Page>
        </Wizard>
      </div>
    </>
  );
};

export default AddPasien;
