import LZString from 'lz-string';
import CryptoJS from 'crypto-js';

function decompress(string) {
  let decompressedString = LZString.decompressFromBase64(string);
  return JSON.parse(decompressedString);
}

function compress(object) {
  // Convert the object to a JSON string
  let jsonString = JSON.stringify(object);

  // Compress the JSON string and encode it to Base64
  let compressedString = LZString.compressToBase64(jsonString);

  return compressedString;
}

function getKeyZ(passphrase, salt) {
  return CryptoJS.PBKDF2(passphrase, CryptoJS.enc.Hex.parse(salt), {
    hasher: CryptoJS.algo.SHA256,
    keySize: 256 / 32,
    iterations: 999,
  });
}

export function decryptData(passphrase, encryptedBase64) {
  var encryptedData = CryptoJS.enc.Base64.parse(encryptedBase64);

  // Extract the salt (first 16 bytes) and IV (next 16 bytes)
  var salt = CryptoJS.lib.WordArray.create(encryptedData.words.slice(0, 4));
  var iv = CryptoJS.lib.WordArray.create(encryptedData.words.slice(4, 8));
  var ciphertext = CryptoJS.lib.WordArray.create(encryptedData.words.slice(8));

  var key = getKeyZ(passphrase, salt.toString());

  var decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
  });

  return decompress(decrypted.toString(CryptoJS.enc.Utf8));
}

export function encryptData(passphrase, plaintext) {
  // Generate a random salt and IV (16 bytes each)
  var salt = CryptoJS.lib.WordArray.random(16);
  var iv = CryptoJS.lib.WordArray.random(16);

  // Derive a key from the passphrase and salt
  var key = getKeyZ(passphrase, salt.toString());

  // Compress the plaintext
  var compressedPlaintext = compress(plaintext);

  // Encrypt the data
  var encrypted = CryptoJS.AES.encrypt(compressedPlaintext, key, {
    iv: iv,
  });

  // Construct the final encrypted data: salt + IV + ciphertext
  var encryptedData = salt.concat(iv).concat(encrypted.ciphertext);

  // Encode the encrypted data to Base64
  var encryptedBase64 = CryptoJS.enc.Base64.stringify(encryptedData);

  return encryptedBase64;
}
