import { API_URL } from "./auth.service";
import authHeader from "./auth-header";
import axios from "axios";

class OtherService {
    saveSurveyKepuasan(data) {
        return axios.post(API_URL + "pwa-pasien/survei-kepuasan", data)
    }

    paketMcu() {
        return axios.get(API_URL + "pwa-referensi/list-paket", { headers: authHeader() })
    }
}

export default new OtherService