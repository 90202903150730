import React from 'react'
import { useState } from "react";
import { Form, Field } from 'react-final-form'
import FooterBar from "../components/FooterBar";
import ShapeRound from '../components/ShapeRound';

const Bmi = () => {
    const [hasil, setHasil] = useState("");
    const [indeks, setIndeks] = useState(0);

    const Error = ({ name }) => (
        <Field
            name={name}
            subscribe={{ touched: true, error: true }}
            render={({ meta: { touched, error } }) =>
                touched && error ? <span className="text-danger">{error}</span> : null
            }
        />
    )
    const required = value => (value ? undefined : 'Wajib diisi');

    const onSubmit = (values) => {
        const heightInMeter = values.tinggi / 100;
        const height2 = Math.pow(heightInMeter, 2);
        const hasilnya = values.berat / height2;
        
        let a;
        if (hasilnya < 18.5) { a = "Berat Rendah"; } else
            if (hasilnya >= 18.5 && hasilnya < 24.9) { a = "Berat Normal"; } else
                if (hasilnya >= 25.0 && hasilnya < 29.9) { a = "Berat Berlebih"; } else { a = "Obesitas" }

        setHasil(a);
        setIndeks(hasilnya);
    }

    return (
        <>
            <FooterBar />
            <div className="page-content">
                <div className="page-title page-title-small">
                    <h2>Body Mass Index</h2>
                </div>
                <ShapeRound />

                <div className="card card-style">
                    <div className="content mb-3 text-center">

                        <Form
                            onSubmit={onSubmit}

                            render={({ handleSubmit, form, submitting, pristine, values }) => (
                                <form onSubmit={handleSubmit}>

                                    <div className="mt-3 input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                        <label className="font-400 font-13">
                                            Berat Badan (kg):
                                        </label>
                                        <Field
                                            name="berat"
                                            component="input"
                                            type="number"
                                            validate={required}
                                        />
                                        <Error name="berat" />
                                    </div>

                                    <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                                        <label className="font-400 font-13">
                                            Tinggi Badan (cm):
                                        </label>
                                        <Field
                                            name="tinggi"
                                            component="input"
                                            type="number"
                                            validate={required}
                                        />
                                        <Error name="tinggi" />
                                    </div>

                                    <button type="submit" className="btn form-control btn-full btn-m rounded-s text-uppercase font-900 shadow-xl bg-highlight">Hitung</button>

                                </form>
                            )}
                        />

                    </div>
                </div>

                {indeks > 0 &&
                    <div className="card card-style">
                        <div className="content mb-3 text-center">

                            <h3 className='font-600'>BMI Anda: </h3>
                            <h3 className='font-600 text-primary'>{indeks.toFixed(2)} | {hasil}</h3>

                            <table
                                className="table table-borderless text-center rounded-sm shadow-l mt-4"
                                style={{ overflow: "hidden" }}
                            >
                                <thead>
                                    <tr className="bg-gray-light">
                                        <th scope="col" className="color-theme py-3 font-14">
                                            Indeks
                                        </th>
                                        <th scope="col" className="color-theme py-3 font-14">
                                            Status
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th scope="row">{"< 18.5"}</th>
                                        <td className="color-yellow-dark">Berat Rendah</td>

                                    </tr>
                                    <tr>
                                        <th scope="row">18.5 - 24.9</th>
                                        <td className="color-green-dark">Berat Normal</td>

                                    </tr>
                                    <tr>
                                        <th scope="row">25.0 - 29.9</th>
                                        <td className="color-blue-dark">Berat Berlebih</td>

                                    </tr>
                                    <tr>
                                        <th scope="row">{"> 30.0"}</th>
                                        <td className="color-red-dark">Obesitas</td>

                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                }

            </div>
        </>
    )
}

export default Bmi