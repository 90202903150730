import axios from "axios";
import authHeader from "./auth-header";
import { API_URL } from "./auth.service";
class PasienService {
  getPasien() {
    return axios.get(API_URL + "pwa-pasien/list-pasien-user", {
      headers: authHeader(),
    });
  }

  infoPasien(idpasien) {
    return axios.post(
      API_URL + "pwa-pasien/get-data-pasien",
      { id_pasien: idpasien },
      { headers: authHeader() }
    );
  }

  daftarPasienBaru(
    pemilik_profil,
    nik,
    namaLengkap,
    jenisKelamin,
    tempatLahir,
    tanggalLahir,
    noHp,
    email,
    agama,
    golDarah,
    pendidikan,
    pekerjaan,
    alamatDomisili,
    alamat,
    provinsi,
    kota,
    kecamatan,
    kelurahan,
    statusKawin,
    namakerabat,
    hubungankerabat,
    nohpkerabat,
    jenis_kelamin_kerabat
  ) {
    return axios.post(
      API_URL + "pwa-pasien/daftar-pasien-baru",
      {
        pemilik_profil: pemilik_profil,
        nik: nik,
        nama_lengkap: namaLengkap,
        jenis_kelamin: jenisKelamin,
        tempat_lahir: tempatLahir,
        tanggal_lahir: tanggalLahir,
        no_hp: noHp,
        email: email,
        id_agama: agama,
        golongan_darah: golDarah,
        id_pendidikan: pendidikan,
        id_pekerjaan: pekerjaan,
        alamat_domisili: alamatDomisili,
        alamat: alamat,
        id_provinsi: provinsi,
        id_kabupaten: kota,
        id_kecamatan: kecamatan,
        id_kelurahan: kelurahan,
        status_perkawinan: statusKawin,
        nama_kerabat: namakerabat,
        hubungan_kerabat: hubungankerabat,
        no_hp_kerabat: nohpkerabat,
        jenis_kelamin_kerabat: jenis_kelamin_kerabat,
      },
      { headers: authHeader() }
    );
  }

  cariPasienLama(data) {
    return axios.post(API_URL + "pwa-pasien/cari-pasien-lama", data, {
      headers: authHeader(),
    });
  }

  validasiPasienLama(data) {
    return axios.post(API_URL + "pwa-pasien/validasi-pasien-lama", data, {
      headers: authHeader(),
    });
  }

  updateProfil(data) {
    Object.assign(authHeader, { "Content-Type": "multipart/form-data" });
    return axios.post(API_URL + "pwa-pasien/validasi-pasien-lama", data, {
      headers: authHeader(),
    });
  }

  hapusProfil(data) {
    return axios.post(API_URL + "pwa-pasien/hapus-pasien-terkait", data, {
      headers: authHeader(),
    });
  }

  uploadGambar(data) {
    const aut = authHeader();
    Object.assign(aut, { "Content-type": "multipart/form" });

    return axios.post(API_URL + "pwa-pasien/upload-gambar", data, {
      headers: authHeader(),
    });
  }

  cekPasienByNik(nik) {
    return axios.post(
      API_URL + "pwa-pasien/cek-pasien-by-nik",
      { nik: nik },
      {
        headers: authHeader(),
      }
    );
  }
}

export default new PasienService();
