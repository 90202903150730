import FooterBar from "../components/FooterBar";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, Fragment } from "react";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import PendukungService from "../services/pendukung.service";
import PasienService from "../services/pasien.service";
import PerjanjianService from "../services/perjanjian.service";
import toast from 'react-hot-toast';
import ShapeRound from '../components/ShapeRound';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import DatePicker from "react-datepicker";
import { subDays, addDays } from "date-fns";
import Form from 'react-bootstrap/Form';
import AuthService from "../services/auth.service";
import {convertDate, konversiTgl } from "../common/convertDate";
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const DokterList = () => {

    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const { state } = useLocation();
    const { idpoli } = state;
    const [idDokter, setIdDokter] = useState("");
    const [idProfil, setIdProfil] = useState("");
    const [jenisPelayanan, setJenisPelayanan] = useState("");
    const [profil, setProfil] = useState([]);
    const [jadwalDokter, setJadwalDokter] = useState([]);
    const [idJadwalPraktek, setIdJadwalPraktek] = useState("");
    const [dokters, setDokter] = useState([]);
    const [searchParam] = useState(["nama_dokter"]);
    const [q, setQ] = useState("");
    const [selectedDate, setSelectedDate] = useState("");
    const [infoJadwal, setInfoJadwal] = useState([]);
    const [dataform, setDataform] = useState("");

    const [selectedDokter, setSelectedDokter] = useState("");
    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [showJadwal, setShowJadwal] = useState(false);
    const [showKonfirmasi, setShowKonfirmasi] = useState(false);

    const user = JSON.parse(localStorage.getItem('aulia_pwa'));
    const token = user ? user.token : bearier_token;

    const handleClose = () => setShow(false);
    const handleCloseKonfirmasi = () => setShowKonfirmasi(false);
    const handleCloseJadwal = () => setShowJadwal(false);
    const handleShowKonfirmasi = () => setShowKonfirmasi(true);
    const handleShow = (iddokterr) => {
        setShow(true);
        setIdJadwalPraktek(null);
        setJadwalDokter([]);
        setSelectedDate("");
        setIdProfil("");

        let selectedDokter = dokters.find(o => o.id_dokter === iddokterr);
        setSelectedDokter(selectedDokter);

        if (!currentUser) {
            toast.error("Untuk membuat janji, anda harus login terlebih dahulu.")
            navigate("/login")
        } else {
            PasienService.getPasien().then((res) => {
                let result = decryptData(token, res.data);
                for (let i = 0; i < result.length; i++) {
                    result[i].id_pasien = encryptData(token, result[i].id_pasien);
                }
                setProfil(result);
                if (res.data.length === 0) {
                    navigate("/profil")
                    toast.error("Silahkan tambah profil pasien terlebih dahulu.")
                }
            })
        }
    }

    const getDokter = () => {
        const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
        PendukungService.getDokter(idpoli).then((res) => {
            let result = decryptData(token, res.data);
            for (let i = 0; i < result.length; i++) {
                result[i].id_dokter = encryptData(token, result[i].id_dokter);
            }
            setDokter(result);
            toast.dismiss(toastId);
        }, error => {
            toast.dismiss(toastId);
            if (error.response.status === 401) {
                localStorage.removeItem("aulia_pwa");
                navigate("/login");
            }
        })
    }

    const filterData = () => {
        return dokters.filter((item) => {
            return searchParam.some((newItem) => {
                return (
                    item[newItem]
                        .toString()
                        .toLowerCase()
                        .indexOf(q.toLowerCase()) > -1
                );
            });
        });
    }

    const tampilDialog = (iddokter) => {
        setIdDokter(iddokter);
        handleShow(iddokter);
    }

    const tampilJadwal = (iddokter) => {
        setShowJadwal(true);
        const toastz = toast.loading('Mohon tunggu...');
        PendukungService.getJadwalByDokter(iddokter).then((res) => {
            const result = decryptData(token, res.data);
            setInfoJadwal(result);
            toast.dismiss(toastz)
        }, error => {
            toast.dismiss(toastz)
            console.log(error);
        })
    }

    const checkJadwalDokter = (date) => {
        setSelectedDate(date);
        var dateconverted = convertDate(date);
        const toastIdx = toast.loading('Sedang mengecek jadwal...');
        PendukungService.cekJadwalDokter({
            idpoli: idpoli, iddokter: idDokter, tgl: dateconverted
        }).then((res) => {
            toast.dismiss(toastIdx);
            let result = decryptData(token, res.data);
            for (let i = 0; i < result.length; i++) {
                result[i].id_jadwal_praktek = encryptData(token, result[i].id_jadwal_praktek);
            }
            setJadwalDokter(result);
            setIdJadwalPraktek(null);
        })
    }

    const onSelectedProfil = ({ target: { value } }) => setIdProfil(value);
    const onClickJadwal = ({ target: { value } }) => setIdJadwalPraktek(value);
    const onSelectedPelayanan = ({ target: { value } }) => setJenisPelayanan(value);

    const onSubmit = (e) => {
        e.preventDefault();
        var tgl = convertDate(selectedDate);

        const formData = new FormData(e.target),
            formDataObj = Object.fromEntries(formData.entries())

        Object.assign(formDataObj, { tanggal_periksa: tgl });
        Object.assign(formDataObj, { dokterTerpilih: selectedDokter });

        setDataform(formDataObj);
        handleShowKonfirmasi();
        handleClose();
    }

    const kirimData = () => {

        const toastSubmit = toast.loading('Mohon tunggu...');
        PerjanjianService.buatPerjanjian(dataform).then((res) => {
            navigate("/")
            toast.dismiss(toastSubmit);
            toast.success("Perjanjian berhasil dibuat.")
        }, error => {
            const resMessage =
                (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();
            toast.dismiss(toastSubmit);
            toast.error(resMessage);
            // if (error.response.status === 401 || error.response.status === 402) navigate("/login");
        })
    }

    useEffect(() => {
        setCurrentUser(currentUser);
        getDokter();

    }, []);

    return (
        <div>
            <FooterBar />
            <div className="page-content">

                <div className="page-title page-title-small">
                    <h2>
                        <a onClick={() => navigate("/layanan")}>
                            <i className="fa fa-arrow-left" />
                        </a>
                        Dokter
                    </h2>
                </div>

                <ShapeRound />

                <div className="card card-style">
                    <div className="content">
                        {/* <h4 className="font-600 mb-2">Pilih Dokter</h4> */}
                        <div className="search-box search-color bg-highlight rounded-s mb-0">
                            <i className="fa fa-search" />
                            <input
                                type="text"
                                className="border-0"
                                placeholder="Cari Dokter... "
                                onChange={(e) => setQ(e.target.value)}
                                value={q}
                            />
                        </div>
                    </div>
                </div>

                {filterData(dokters).map((d) => (

                    <div className="card card-style mb-3" key={d.id}>
                        <div className="card-body">
                            <div className="content">
                                <div className="d-flex">
                                    <div>
                                        {!d.gambar ?
                                            <img
                                                src="/assets/images/avatars/5s.png"
                                                // src={d.gambar}
                                                width={50}
                                                className="me-3 ms-n3 bg-highlight rounded-xl"
                                            /> :
                                            <img
                                                // src="/assets/images/avatars/5s.png"
                                                src={d.gambar}
                                                width={50}
                                                className="me-3 ms-n3 bg-highlight rounded-xl"
                                            />}
                                    </div>
                                    <div>
                                        <h5 className="font-600 mb-0 pt-1">{d.nama_dokter}</h5>
                                        <span className="font-12"><i className="fa fa-map-marker"></i> {d.poli}</span>
                                    </div>
                                </div>
                            </div>

                            <div className="d-flex justify-content-between">
                                <button onClick={() => tampilJadwal(d.id_dokter)} className="btn btn-full btn-xxs rounded-s text-uppercase font-900 shadow-xl border-highlight color-highlight">Lihat Jadwal</button>
                                <button onClick={() => tampilDialog(d.id_dokter)} className="btn btn-full btn-xxs rounded-s text-uppercase font-900 shadow-xl bg-highlight">Buat Janji</button>
                            </div>

                        </div>
                    </div>
                ))}

            </div>

            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
            // fullscreen={true}
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className="font-16 font-600">Tentukan Jadwal</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>

                        <div style={{ width: "100%" }}>
                            <DatePicker
                                onChange={(date) => checkJadwalDokter(date)}
                                minDate={subDays(new Date(), 0)}
                                maxDate={addDays(new Date(), 31)}
                                dateFormat="dd MMMM yyyy"
                                required
                                inline
                                name="tanggal"
                                value={selectedDate}
                            />
                        </div>

                        {jadwalDokter.length === 0 ? ""
                            :
                            <>
                                <span>Pilih jam praktek:</span><br />
                                {jadwalDokter.map((type) => (
                                    <label key={type.id_jadwal_praktek} className="mb-3">

                                        <input
                                            type="radio"
                                            value={type.id_jadwal_praktek}
                                            onChange={onClickJadwal}
                                            name="id_jadwal_praktek" className="card-input-element font-900" />

                                        <div className="ms-0 p-2 rounded-s border-highlight card-default card-input">
                                            <div className="card-body color-highlight">
                                                <i className="fa fa-clock"></i> {type.jam_mulai} - {type.jam_selesai}
                                            </div>
                                        </div>
                                    </label>
                                ))
                                }
                            </>

                        }
                        <div className="divider"></div>
                        <div className="input-style has-borders hnoas-icon input-style-always-active validate-field">
                            <select name="id_pasien" value={idProfil} onChange={onSelectedProfil}>
                                <option></option>
                                {profil.map((a) => (
                                    <Fragment key={a.id_pasien}>
                                        <option value={a.id_pasien}>{a.nama_lengkap}</option>
                                    </Fragment>
                                ))}
                            </select>

                            <label className="color-highlight font-400 font-13">
                                Profil Pasien
                            </label>
                        </div>

                        <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                            <select name="jenis_pelayanan" value={jenisPelayanan} onChange={onSelectedPelayanan}>
                                <option value="Umum">Umum</option>
                                <option value="Perusahaan">Asuransi & Perusahaan</option>
                            </select>

                            <label className="color-highlight font-400 font-13">
                                Pembayaran
                            </label>
                        </div>

                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-full btn-border btn-sm rounded-s text-uppercase font-900 shadow-l" variant="secondary" onClick={handleClose}>
                            Batal
                        </Button>
                        {jadwalDokter.length === 0 ? ""
                            :
                            idJadwalPraktek === null ? ""
                                :
                                <Button type="submit" className="btn btn-full btn-sm rounded-s text-uppercase font-900 shadow-xl bg-highlight">Buat Janji </Button>

                        }
                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showJadwal}
                onHide={handleCloseJadwal}
                backdrop="static"
                keyboard={false}
            >
                <Form onSubmit={onSubmit}>
                    <Modal.Header closeButton>
                        <Modal.Title className="font-16 font-600">Info Jadwal Praktek</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <table
                            className="table table-borderless text-center rounded-sm shadow-l"
                            style={{ overflow: "hidden" }}
                        >
                            <thead>
                                <tr className="bg-gray-light">
                                    <th scope="col" className="color-theme py-3 font-14">
                                        Hari
                                    </th>
                                    <th scope="col" className="color-theme py-3 font-14">
                                        Jam
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {infoJadwal.map((j) => (
                                    <tr>
                                        <th scope="row">{j.hari}</th>
                                        <td>
                                            {j.jam_mulai} - {j.jam_selesai}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button className="btn btn-full btn-border btn-sm rounded-s text-uppercase font-900 shadow-l" onClick={handleCloseJadwal} variant="secondary">
                            Tutup
                        </Button>

                    </Modal.Footer>
                </Form>
            </Modal>

            <Modal
                show={showKonfirmasi}
                onHide={handleCloseKonfirmasi}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title className="font-16 font-600">Konfirmasi</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Benar anda ingin membuat perjanjian dengan data berikut ini?
                        {dataform &&
                            <table className="mt-4 table table-sm">
                                <thead>
                                    <tr>
                                        <th>Poliklinik</th><td>:</td><td>{dataform.dokterTerpilih.poli}</td>
                                    </tr>
                                    <tr>
                                        <th>Dokter</th><td>:</td><td>{dataform.dokterTerpilih.nama_dokter}</td>
                                    </tr>
                                    <tr>
                                        <th>Tanggal</th><td>:</td><td>{konversiTgl(dataform.tanggal_periksa)}</td>
                                    </tr>
                                    <tr>
                                        <th>Pembayaran</th><td>:</td><td>{dataform.jenis_pelayanan}</td>
                                    </tr>
                                </thead>
                            </table>
                        }
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-full btn-border btn-sm rounded-s text-uppercase font-900 shadow-l" onClick={handleCloseKonfirmasi} variant="secondary">
                        Tutup
                    </Button>
                    <Button className="btn btn-full btn-border bg-highlight btn-sm rounded-s text-uppercase font-900 shadow-l" onClick={kirimData}>
                        Ya, Lanjutkan
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default DokterList;