import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FooterBar from '../components/FooterBar';
import AuthService from "../services/auth.service";
import toast from 'react-hot-toast';

const RegisterForm = () => {
    
    const [nik, setNik] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [repassword, setRepassword] = useState("");
    const [validForm, setValidForm] = useState(true);
    const [validMsg, setValidMsg] = useState(true);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const navigate = useNavigate();

    const Registrasi = (e) => {
        e.preventDefault();
        const toastId = toast.loading('Mohon tunggu...');
        
        // Menonaktifkan tombol saat diklik
        setIsButtonDisabled(true);

        if (nik === "" || email === "" || password === "" || repassword === "") {
            setValidForm(false);
            setValidMsg("Mohon isi form dengan benar!");
            toast.dismiss(toastId);
        } else if (repassword !== password) {
            setValidForm(false);
            setValidMsg("Password belum cocok!");
            toast.dismiss(toastId);
        } else {
            AuthService.register(nik, email, password, repassword).then(
                () => {
                    toast('Registrasi berhasil. Silahkan cek inbox email anda untuk verifikasi.', {
                        duration: 5000,
                        position: 'top-center',
                        // Styling
                        style: {},
                        className: '',
                        // Custom Icon
                        icon: '👏',
                        // Change colors of success/error/loading icon
                        iconTheme: {
                            primary: '#000',
                            secondary: '#fff',
                        },
                        // Aria
                        ariaProps: {
                            role: 'status',
                            'aria-live': 'polite',
                        },
                    });
                    localStorage.removeItem("aulia_pwa");
                    navigate("/login"); // arah ke daftar rawat jalan
                    toast.dismiss(toastId);
                },
                error => {
                    toast.dismiss(toastId);
                    const resMessage =
                        (error.response && error.response.data && error.response.data.msg) ||
                        error.message ||
                        error.toString();

                    setValidForm(false);
                    setValidMsg(resMessage);
                }
            );
        }

        // Mengaktifkan kembali tombol setelah 5 detik
        setTimeout(() => {
            setIsButtonDisabled(false);
        }, 5000);
    };

    return (
        <>
            <FooterBar />
            <div className="card" style={{ marginBottom: "-70px", height: "230px" }}>
                <div className="card-center text-center">
                    <h1 className="color-white font-600 mb-n1 mt-n4 font-32 font-800">AULIA HOSPITAL</h1>
                    <p className="color-white opacity-70">Your Healthcare Solution</p>
                </div>
                <div className="card-overlay bg-highlight opacity-95" />
                <div className="card-overlay dark-mode-tint" />
                <div className="card-bg preload-img" />
            </div>

            <div className="card card-style">
                <div className="content mb-0">
                    <div className="d-flex">
                        <div className="me-auto">
                            <h4 className="font-600">Daftar Akun</h4>
                            <p className="font-11 mt-n2 mb-3">Silahkan lengkapi form dibawah ini:</p>
                        </div>
                        <div className="ms-auto">
                            <i data-feather="user"
                                data-feather-line="1"
                                data-feather-size="40"
                                data-feather-color="blue-dark"
                                data-feather-bg="blue-fade-light">
                            </i>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <form onSubmit={Registrasi}>
                        <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                            <input
                                type="number"
                                className="form-control validate-name"
                                required
                                value={nik} onChange={(e) => setNik(e.target.value)}
                            />
                            <label htmlFor="form1" className="color-highlight font-400 font-13">
                                NIK
                            </label>
                            <i className="fa fa-times disabled invalid color-red-dark" />
                            <i className="fa fa-check disabled valid color-green-dark" />
                            <em>(required)</em>
                        </div>
                        <div className="input-style has-borders no-icon input-style-always-active validate-field mb-4">
                            <input
                                type="email"
                                className="form-control validate-email"
                                required
                                value={email} onChange={(e) => setEmail(e.target.value)}
                            />
                            <label htmlFor="form2" className="color-highlight font-400 font-13">
                                Email
                            </label>
                            <i className="fa fa-times disabled invalid color-red-dark" />
                            <i className="fa fa-check disabled valid color-green-dark" />
                            <em>(required)</em>
                        </div>

                        <div className="input-style has-borders no-icon input-style-always-active validate-field mb-4">
                            <input
                                type="password"
                                className="form-control validate-password"
                                required
                                value={password} onChange={(e) => setPassword(e.target.value)}
                            />
                            <label htmlFor="form4" className="color-highlight font-400 font-13">
                                Password
                            </label>
                            <i className="fa fa-times disabled invalid color-red-dark" />
                            <i className="fa fa-check disabled valid color-green-dark" />
                            <em>(required)</em>
                        </div>
                        <div className="input-style has-borders no-icon input-style-always-active validate-field mb-4">
                            <input
                                type="password"
                                className="form-control validate-password"
                                required
                                value={repassword} onChange={(e) => setRepassword(e.target.value)}
                            />
                            <label htmlFor="form4" className="color-highlight font-400 font-13">
                                Ulangi Password
                            </label>
                            <i className="fa fa-times disabled invalid color-red-dark" />
                            <i className="fa fa-check disabled valid color-green-dark" />
                            <em>(required)</em>
                        </div>

                        {!validForm && <p className='text-danger'>** Oops, {validMsg}</p>}

                        <button
                            type="submit"
                            className="btn form-control btn-m mt-4 mb-4 btn-full bg-highlight rounded-sm text-uppercase font-900"
                            disabled={isButtonDisabled}
                        >
                            Daftar
                        </button>
                    </form>
                    <div className="me-auto">
                        <p className="font-11 mt-n2 mb-3">Sudah punya akun? <Link to="/login">Silahkan masuk</Link></p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default RegisterForm;