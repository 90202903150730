import { useEffect, useState } from "react";
import PasienService from "../services/pasien.service";
import AuthService from "../services/auth.service";
import FooterBar from "../components/FooterBar";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ShapeRound from "../components/ShapeRound";
import Error from "../components/Error";

import Lottie from 'react-lottie-player';
import lottieJson from '../lottie/73061-search-not-found.json';
import { Figure } from "react-bootstrap";

import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const Profil = () => {

  const [pasien, setPasien] = useState([]);
  const [filektp, setfilektp] = useState("");
  const [fileasuransi, setfileasuransi] = useState("");
  const [preview, setPreview] = useState("");
  const [detailPasien, setDetailPasien] = useState("");
  const [idProfil, setIdProfil] = useState("");
  const navigate = useNavigate();
  const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());

  const [show, setShow] = useState(false);
  const [showDetail, setShowDetail] = useState(false);
  const [showKonfirmHapus, setShowKonfirmHapus] = useState(false);
  const [error, setError] = useState(false);
  const [selectedIdHapus, setSelectedIdHapus] = useState("");

  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const handleClose = () => setShow(false);
  const handleCloseKonfirmHapus = () => setShowKonfirmHapus(false);
  const handleCloseDetail = () => {
    setShowDetail(false);
    setIdProfil("");
    setfilektp("");
    setfileasuransi("");
  }
  const handleShow = () => setShow(true);
  const handleShowKonfirmHapus = (idpasien) => {
    setSelectedIdHapus(idpasien);
    setShowKonfirmHapus(true);
  }
  const goNew = () => navigate("/add-pasien");
  const goPernah = () => navigate("/tautkan-pasien");
  const handleShowDetail = (idpasien) => {
    setShowDetail(true);
    setPreview("");
    setIdProfil(idpasien);
    const tp = toast.loading("Mohon tunggu...")
    PasienService.infoPasien(idpasien).then((res) => {
      const result = decryptData(token, res.data);
      setDetailPasien(result);
      toast.dismiss(tp)
      // console.log(res.data.berkas[1]);
    }, error => {
      toast.dismiss(tp)
    })
  }

  const loadImage = (e) => {
    const img = e.target.files[0];
    setfilektp(img);
    setPreview(URL.createObjectURL(img));
  }

  const loadImage2 = (e) => {
    const imgz = e.target.files[0];
    setfileasuransi(imgz);
    setPreview(URL.createObjectURL(imgz));
  }

  const getPasiens = () => {
    const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
    PasienService.getPasien(currentUser.nik, currentUser.token).then(
      (res) => {
        let result = decryptData(token, res.data);
        for (let i = 0; i < result.length; i++) {
          result[i].id_pasien = encryptData(token, result[i].id_pasien);
        }
        setPasien(result);
        toast.dismiss(toastId);
        // console.log(res.data);
      },
      error => {
        toast.dismiss(toastId);
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();
        if (error.response.status === 500) setError(true);
        if (error.response.status === 401 || error.response.status === 402) {
          localStorage.removeItem("aulia_pwa");
          navigate("/login");
        }

      }
    );
  }

  const hapusProfil = () => {
    const toah = toast.loading("Mohon tunggu...");
    PasienService.hapusProfil({ id_pasien: selectedIdHapus }).then((res) => {
      toast.dismiss(toah);
      getPasiens();
      handleCloseKonfirmHapus();
    }, error => {
      toast.dismiss(toah);
      handleCloseKonfirmHapus();
    })
  }

  const updateProfil = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file_ktp", filektp);
    formData.append("file_asuransi", fileasuransi);
    formData.append("id_pasien", idProfil);
    // console.log(formData);
    const toau = toast.loading("Mohon tunggu...");
    PasienService.uploadGambar(formData).then((res) => {
      toast.dismiss(toau);
      toast.success("Pembaharuan berhasil disimpan.");
    }, error => {
      toast.dismiss(toau);
    })
  }

  useEffect(() => {
    setCurrentUser(currentUser);
    if (!currentUser) {
      toast.error("Untuk mengakses halaman ini, anda harus login terlebih dahulu.")
      navigate("/login");
      // window.location.reload();
    } else {
      getPasiens();
    }
  }, []);

  return (
    <>
      <FooterBar />
      <div className="page-content">
        <div className="page-title page-title-small">
          <h2>
            Profil Pasien
          </h2>
        </div>

        <ShapeRound />

        {error ? <Error /> :
          <div>
            {pasien.length === 0 ?

              <div className="card card-style">
                <div className="content">
                  <div className="d-flex">

                    <div>
                      <h2 className="mb-0 pt-1">Belum ada profil.</h2>

                      <p>
                        Silahkan klik tombol berikut untuk membuat atau menautkan data profil pasien, baik diri sendiri ataupun keluarga/ kerabat anda.
                      </p>
                      <button onClick={() => handleShow()}
                        className="btn form-control btn-m btn-full rounded-s bg-highlight text-uppercase font-900"
                      >
                        Tambah/ Tautkan Profil Pasien
                      </button>
                      <Lottie
                        loop
                        animationData={lottieJson}
                        play
                      />
                    </div>
                  </div>
                </div>
              </div>

              :
              <>
                <div className="card card-style">
                  <div className="content">
                    <span>
                      Untuk menambah profil pasien, silahkan tap tombol berikut:
                    </span>
                    <button onClick={() => handleShow()}
                      className="mt-3 btn form-control btn-m btn-full rounded-s bg-highlight text-uppercase font-900"
                    >
                      Tambah Profil Pasien
                    </button>
                  </div>
                </div>

                {pasien.map((p, i) => (
                  <div className="card card-style mb-3" key={i}>
                    <div className="card-body">
                      <div className="content">
                        <div className="d-flex">
                          <div>
                            {p.jenis_kelamin === "Laki-laki" ?
                              <img
                                src="/png/man.png"
                                width={50}
                                className="me-3 ms-n3 bg-highlight rounded-xl"
                              />
                              :
                              <img
                                src="/png/woman.png"
                                width={50}
                                className="me-3 ms-n3 bg-highlight rounded-xl"
                              />}
                          </div>
                          <div>
                            <h5 className="font-600 mb-0 pt-1">{p.nama_lengkap}</h5>
                            <span className="font-12"><i className="fa fa-id-card"></i> {p.nik}</span><br/>
                            {p.status === "Terverifikasi" ?
                              <span className="font-12 color-highlight"><i className="fa fa-check-circle"></i> {p.status}</span> :
                              <span className="font-12 color-red-light"><i className="fa fa-times-circle"></i> {p.status}</span>
                            }
                          </div>
                        </div>

                      </div>
                      <div className="d-flex justify-content-between">
                        <button className="btn btn-full btn-border btn-xxs rounded-s text-uppercase font-900 shadow-l border-highlight color-highlight">{p.jenis_pasien === "Utama" ? "Diri Sendiri" : "Orang Lain"}</button>

                        {p.jenis_pasien !== "Utama" && <button onClick={() => handleShowKonfirmHapus(p.id_pasien)} className="btn btn-full btn-xxs rounded-s text-uppercase font-900 shadow-xl bg-danger">Hapus Profil</button>}

                        <button onClick={() => handleShowDetail(p.id_pasien)} className="btn btn-full btn-xxs rounded-s text-uppercase font-900 shadow-xl bg-highlight">Detail Profil</button>
                      </div>

                      {/* detail profil disini */}

                    </div>

                  </div>
                ))}
              </>
            }

          </div>
        }

      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Apakah sebelumnya pernah menjalani perawatan di Aulia Hospital?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl" onClick={goNew}>
            Belum
          </Button>
          <Button className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl bg-highlight" onClick={goPernah}>Ya, Pernah</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showDetail}
        onHide={handleCloseDetail}
        backdrop="static"
        keyboard={false}
        centered
      >
        <form onSubmit={updateProfil}>
          <Modal.Header closeButton>
            <Modal.Title className="font-16 font-600">Detail Informasi</Modal.Title>
          </Modal.Header>
          <Modal.Body>

            {detailPasien !== "" &&

              <table
                className="table  rounded-sm"
                style={{ overflow: "hidden" }}
              >
                <tbody>
                  <tr>
                    <th>NIK</th><td>{detailPasien.identitas_personal.no_identitas}</td>
                  </tr>

                  <tr>
                    <th>Nama</th><td>{detailPasien.personal.nama_lengkap}</td>
                  </tr>

                  <tr>
                    <th>Tgl. Lahir</th><td>{detailPasien.personal.tanggal_lahir}</td>
                  </tr>
                  <tr>
                    <th>Alamat</th><td>{detailPasien.personal.alamat_domisili}</td>
                  </tr>
                  {/* <tr>
                    <th>Foto KTP</th><td>
                      {detailPasien.berkas.length === 0 || typeof detailPasien.berkas[0] === 'undefined' ?
                      <input type="file" className="file-input" onChange={loadImage} />
                      :
                      <a target="_blank" href={detailPasien.berkas[0].file}>Lihat file</a>
                      }
                    </td>
                  </tr>
                  <tr>
                    <th>Foto Asuransi</th><td>
                      {detailPasien.berkas.length === 0 || typeof detailPasien.berkas[1] === 'undefined' ?
                      <input type="file" className="file-input" onChange={loadImage2} />
                      :
                    
                      <a target="_blank" href={detailPasien.berkas[1].file}>Lihat file</a>
                      }
                    </td>
                  </tr> */}
                </tbody>
              </table>
            }

            {preview ? (
              <div className="row text-center">
                <div className="col-12">
                  <h5>Preview</h5>
                  <Figure>
                    <Figure.Image
                      width={200}
                      height={200}
                      alt="preview"
                      src={preview}
                    />
                  </Figure>
                </div>
              </div>
            ) : ""}

          </Modal.Body>
          <Modal.Footer>
            {/* <Button className="btn btn-full btn-sm rounded-s text-uppercase font-900 shadow-xl bg-highlight" variant="primary" type="submit">Simpan Perubahan</Button> */}
            <Button className="btn btn-full btn-sm rounded-s text-uppercase font-900 shadow-xl" variant="secondary" onClick={handleCloseDetail}>
              Tutup
            </Button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal
        show={showKonfirmHapus}
        onHide={handleCloseKonfirmHapus}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Konfirmasi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Benar anda ingin menghapus profil ini dari akun anda?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl" onClick={handleCloseKonfirmHapus}>
            Batal
          </Button>
          <Button className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl bg-highlight" onClick={hapusProfil}>Ya, Hapus</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Profil