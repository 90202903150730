import React, { useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import FooterBar from '../components/FooterBar';
import ShapeRound from '../components/ShapeRound';
import { Form, Field } from 'react-final-form';
import PasienService from '../services/pasien.service';

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import toast from 'react-hot-toast';
import InputMask from 'react-input-mask'

import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const TautkanPasien = () => {
  const [startDate, setStartDate] = useState(new Date());
  const navigate = useNavigate();
  const [pasienLama, setPasienLama] = useState({})
  const [show, setShow] = useState(false);
  const [showTgl, setShowTgl] = useState(false);
  const [tanggalLahir, setTanggalLahir] = useState("");
  const [idPasien, setIdPasien] = useState(null);
  const [jenisPasien, setJenisPasien] = useState("");

  const user = JSON.parse(localStorage.getItem('aulia_pwa'));
  const token = user ? user.token : bearier_token;

  const handleClose = () => {
    setShow(false);
  }
  const handleShow = () => setShow(true);
  const handleCloseTgl = () => {
    setShowTgl(false);
    setTanggalLahir(new Date());
  }
  const handleShowTgl = () => setShowTgl(true);
  const ketemu = () => {
    handleClose();
    handleShowTgl();
  }

  const required = value => (value ? undefined : 'wajib diisi')
  const onSubmit = (values) => {
    const toastId = toast.loading('Mohon tunggu, sedang mengambil data...');
    PasienService.cariPasienLama(values).then((res) => {
      let result = decryptData(token, res.data);
      result.id_pasien = encryptData(token, result.id_pasien);
      setPasienLama(result);
      setIdPasien(result.id_pasien);
      setJenisPasien(values.jenis_pasien);

      handleShow();
      toast.dismiss(toastId);
    }, error => {
      toast.dismiss(toastId);
      const resMessage =
        (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();

      toast.error(resMessage);
      if (error.response.status === 401) {
        localStorage.removeItem("aulia_pwa");
        navigate("/login");
      }
    }
    )
  }

  const validasi = () => {
    const toastIdx = toast.loading('Mohon tunggu, sedang mengambil data...');
    PasienService.validasiPasienLama({ id_pasien: idPasien, tanggal_lahir: tanggalLahir, jenis_pasien: jenisPasien }).then((res) => {
      navigate("/profil")
      toast.dismiss(toastIdx);
    }, error => {
      toast.dismiss(toastIdx);
      const resMessage =
        (error.response && error.response.data && error.response.data.msg) || error.message || error.toString();
      const errStatus = error.response.status;

      if (errStatus === 401) {
        localStorage.removeItem("aulia_pwa");
        navigate("/login");
      }
      if (errStatus === 400) toast.error(resMessage);
    }
    )
  }

  return (
    <>
      <FooterBar />
      <div className='page-content'>
        <div className="page-title page-title-small">
          <h2>
            Tautkan Profil Pasien
          </h2>
        </div>

        <ShapeRound />

        <div className="card card-style">
          <div className="content pt-3">

            <Form
              onSubmit={onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <Field name="jenis_pasien" component="select">
                      <option>Pilih</option>
                      <option value="diri_sendiri">Diri Sendiri</option>
                      <option value="orang_lain">Orang Lain</option>
                    </Field>
                    <label className="color-highlight font-400 font-13">
                      Pemilik Profil
                    </label>
                  </div>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <Field name="filter" component="select">
                      <option>Pilih</option>
                      <option value="nik">NIK KTP</option>
                      <option value="no_rm">No. Rekam Medis</option>
                    </Field>
                    <label className="color-highlight font-400 font-13">
                      Jenis Validasi
                    </label>
                  </div>

                  <div className="input-style has-borders hnoas-icon input-style-always-active validate-field mb-4">
                    <Field name="nomor" validate={required}>
                      {({ input, meta }) => (
                        <div>
                          <input {...input} type="text" />
                          {meta.error && meta.touched && <span>{meta.error}</span>}
                        </div>
                      )}
                    </Field>
                    <label className="color-highlight font-400 font-13">
                      NIK/ No. Rekam Medis
                    </label>
                  </div>

                  <div className='row'>
                    <div className='col-6'>
                      <Link to="/profil" className='btn btn-full btn-border btn-sm rounded-s text-uppercase font-900 shadow-l border-highlight color-highlight'>
                        Kembali
                      </Link>
                    </div>
                    <div className='col-6'>
                      <div className="buttons">
                        <button className='btn form-control btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl bg-highlight' type="submit" disabled={submitting}>
                          Tautkan
                        </button>
                      </div>
                    </div>
                  </div>

                </form>
              )}
            />

          </div>
        </div>

      </div>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Berikut ini mungkin data pasien yang anda cari:</p>

          <table
            className="table table-borderless rounded-sm shadow-l"
            style={{ overflow: "hidden" }}
          >

            <tbody>
              <tr className="bg-dark-light">
                <th scope="row">Nama Lengkap</th>
                <td>{pasienLama.nama_lengkap}</td>
              </tr>
              <tr className="bg-dark-light">
                <th scope="row">NIK</th>
                <td> {pasienLama.NIK}</td>
              </tr>
              <tr className="bg-dark-light">
                <th scope="row">Domisili</th>
                <td>{pasienLama.alamat_domisili}</td>
              </tr>
            </tbody>
          </table>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl" onClick={handleClose}>
            Bukan
          </Button>
          <Button className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl bg-highlight" onClick={ketemu}>Ya, Benar</Button>
        </Modal.Footer>
      </Modal>

      <Modal
        show={showTgl}
        onHide={handleCloseTgl}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <label>Masukkan tanggal lahir pasien:</label>
          <div className="input-style has-borders no-icon mb-4">
            {/* <input type="date" value={tanggalLahir} max="2030-01-01" min="1940-01-01" className="form-control validate-text" onChange={(e) => setTanggalLahir(e.target.value)} /> */}
            <InputMask
              mask='99-99-9999'
              placeholder='dd-mm-yyyy'
              value={tanggalLahir}
              onChange={(e) => setTanggalLahir(e.target.value)}>
            </InputMask>
            <label className="color-highlight">Select Date</label>
            <i className="fa fa-check disabled valid me-4 pe-3 font-12 color-green-dark"></i>
            <i className="fa fa-check disabled invalid me-4 pe-3 font-12 color-red-dark"></i>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl" onClick={handleCloseTgl}>
            Batal
          </Button>
          <Button className="btn btn-full btn-sm rounded-s text-uppercase font-700 shadow-xl bg-highlight" onClick={validasi}>Validasi</Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default TautkanPasien