import FooterBar from "../components/FooterBar";
import { Link, useSearchParams, createSearchParams } from "react-router-dom";
import AuthService from "../services/auth.service";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "axios";
import authHeader from '../services/auth-header';
import CardTitle from "../components/CardTitle";

const PendaftaranRawatJalan = () => {

    const [currentUser, setCurrentUser] = useState(AuthService.getCurrentUser());
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // cek token ke server
    const checkToken = () => {
        AuthService.checkToken().then((res) => {

        }, error => {
            if (error.response.status === 401 || error.response.status === 402) {
                localStorage.removeItem("aulia_pwa");
                navigate("/login");
            }
        })
    }

    const gotoPoli = (jenislayanan) => {
        navigate({
            pathname: '/poliklinik',
            search: createSearchParams({ r: searchParams.get('r'), j: jenislayanan }).toString(),
        })
    }

    useEffect(() => {

        checkToken();

        if (!currentUser) {
            localStorage.removeItem("aulia_pwa");
            navigate("/login");
            // window.location.reload();
        }
        setCurrentUser(currentUser);
    }, []);

    return (
        <>
            <div>
                <FooterBar />
                <div className="page-content">
                    <div className="page-title page-title-small">
                        <h2>
                            <a onClick={() => navigate("/")}>
                                <i className="fa fa-arrow-left" />
                            </a>

                            Buat Perjanjian

                        </h2>
                    </div>
                    <div className="card header-card shape-rounded" style={{ height: "150px" }}>
                        <div className="card-overlay bg-highlight opacity-95" />
                        <div className="card-overlay dark-mode-tint" />
                        <div className="card-bg preload-img" />
                    </div>

                    <CardTitle>
                        <div>
                            <h2 className="mb-0 pt-1">Pilih Penjamin</h2>
                            <p>
                                Silahkan pilih penjamin anda.
                            </p>
                        </div>
                    </CardTitle>

                    <a onClick={() => gotoPoli("Umum")}
                        className="card card-style mb-3 d-flex"
                        style={{ height: "100px" }}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="ps-3 ms-1 align-self-center">
                                <h4 className="font-600 mb-0 pt-4 mt-1 mb-1">Umum</h4>
                                <p className="color-highlight mt-n1 font-14">
                                    Pasien Umum/ Mandiri
                                </p>
                            </div>
                            <div className="pe-3 align-self-center">
                                <img src="/assets/png/medical-record.png" style={{ width: "55px" }} />
                            </div>
                        </div>
                    </a>
                    <a onClick={() => gotoPoli("Perusahaan")}
                        className="card card-style mb-3 d-flex"
                        style={{ height: "100px" }}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="ps-3 ms-1 align-self-center">
                                <h4 className="font-600 mb-0 pt-4 mt-1 mb-1">Asuransi & Perusahaan</h4>
                                <p className="color-highlight mt-n1 font-14">
                                    Pasien yang memiliki Asuransi
                                </p>
                            </div>
                            <div className="pe-3 align-self-center">
                                <img src="/assets/png/insurance.png" style={{ width: "55px" }} />
                            </div>
                        </div>
                    </a>
                    {/* <a onClick={() => gotoPoli("Perusahaan")}
                        className="card card-style mb-3 d-flex"
                        style={{ height: "100px" }}
                    >
                        <div className="d-flex justify-content-between">
                            <div className="ps-3 ms-1 align-self-center">
                                <h4 className="font-600 mb-0 pt-4 mt-1 mb-1">BPJS Kesehatan</h4>
                                <p className="color-highlight mt-n1 font-14">
                                    Pendaftaran Pasien BPJS Kesehatan
                                </p>
                            </div>
                            <div className="pe-3 align-self-center">
                                <img src="/assets/png/bpjs.png" style={{ width: "55px" }} />
                            </div>
                        </div>
                    </a> */}
                </div>
            </div>

        </>
    )
}

export default PendaftaranRawatJalan;