
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { useEffect, useState } from 'react';
import PendukungService from '../services/pendukung.service';
import { bearier_token } from "../services/auth.service";
import { decryptData, encryptData } from "../helpers/Secure";

const HomeSlider = () => {

    const [imgList, setImgList] = useState([]);

    const user = JSON.parse(localStorage.getItem('aulia_pwa'));
    const token = user ? user.token : bearier_token;

    const getSliderImg = () => {
        PendukungService.gambarSlider().then((res) => {
            const result = decryptData(token, res.data);
            setImgList(result);
        })
    }

    useEffect(() => {
        getSliderImg();
    }, [])

    return (

        <div className='card card-style'>
            <div className='card-content'>
                <Splide options={{
                    rewind: true,
                    type: 'loop',
                    gap: '1rem',
                    autoplay: true,
                    pagination: false,
                    autoWidth: true,
                    lazyLoad: true
                }} aria-label="Slide">

                    {imgList.length === 0 ? "" :
                        imgList.map((img, i) => (
                            <SplideSlide >
                                <img src={img.file_base64} style={{ height: 280 }}></img>
                            </SplideSlide>
                        ))
                    }

                </Splide>
            </div>
        </div>

    )
}

export default HomeSlider;