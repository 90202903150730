
const CardTitle = ({children}) => {
  return (
    <div className="card card-style">
      <div className="content">
        <div className="d-flex">

          {children}

        </div>
      </div>
    </div>
  )
}

export default CardTitle